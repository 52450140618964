const ko = {
  "global": {
    "language": "KR",
    "customer_service": "고객센터",
    "customer_account_tips": "⚠️본인 채널이 아닐 경우, 서비스 이용이 제한될 수 있습니다. ",
    "customer_service_tips": "궁금한 점이 있으신가요? [{0}]를 찾아주세요!🧏‍♀️",
    "customer_service_subtitle": "문제가 있으신가요? \n스크린샷/영상을 준비해 주시면 더욱 빠른 문제해결이 가능해요. \n{0} 이동하기",
    "customer_service_link": "고객센터",
    "customer_service_email": "C/S 이메일",
    "email_holder": "이메일을 입력하세요",
    "email_error": "잘못된 이메일 형식이에요",
    "password_holder": "비밀번호를 입력하세요",
    "password_error": "(8~20자) 대소문자, 특수문자, 숫자를 모두 포함한 안전한 비밀번호를 설정하세요",
    "password_confirm_error": "비밀번호가 일치하지 않아요",
    "phone_holder": "휴대전화를 입력하세요",
    "code_holder": "인증코드를 입력하세요",
    "code_error": "잘못된 인증코드입니다",
    "code_get": "인증코드 받기",
    "code_resend": "다시 받기",
    "agreement_all_agree": "모두 동의합니다.",
    "agreement_age_14": "만 14세 이상입니다.",
    "agreement_age_18": "만 18세 이상입니다.",
    "protocol_checkbox_user": "{0} 이용약관",
    "protocol_checkbox_privacy": "{0} 개인정보처리방침",
    "agreement_message_tip": "필수 항목에 동의해주세요",
    "btn_add": "추가",
    "btn_change": "수정",
    "btn_delete": "삭제",
    "btn_confirm": "확인",
    "btn_consider": "더 고민해 볼게요",
    "btn_close": "닫기",
    "btn_submit": "제출",
    "btn_cancel": "취소",
    "btn_know": "네, 알겠습니다",
    "btn_check": "상세보기",
    "btn_view_more": "상세보기",
    "btn_last_step": "이전",
    "btn_next_step": "다음",
    "btn_agree": "동의",
    "btn_disagree": "비동의",
    "btn_go": "바로가기",
    "btn_signin": "로그인",
    "btn_google_signin": "구글 계정으로 로그인",
    "btn_send": "발송",
    "btn_verify": "인증",
    "btn_done": "완료",
    "btn_save": "저장",
    "btn_put_away": "접기",
    "btn_expand": "더보기",
    "btn_edit": "수정",
    "btn_view_in_mobile": "모바일로 보기",
    "modal_open_platform_title": "채널개설을 먼저 진행해 주세요.",
    "modal_open_platform_subtitle": "새로운 채널에서 더 많은 구독자를 만나보세요!",
    "modal_open_platform_btn": "채널 개설 신청",
    "total_count": "총 {0} 개 데이터",
    "list_no_more": "이미 모든 데이터를 확인했습니다.",
    "message_success_create": "신규 추가 성공",
    "message_error_create": "신규 추가 실패",
    "message_success_update": "수정 성공",
    "message_error_update": "수정 실패",
    "message_success_delete": "삭제 되었어요",
    "message_error_delete": "다시 시도해주세요",
    "message_success_upload": "업로드 성공",
    "message_error_upload": "다시 시도해주세요",
    "message_success_bind": "연동 성공",
    "message_error_bind": "다시 시도해주세요",
    "message_no_token": "로그인 유효시간이 초과되었어요, 다시 시도해 주세요",
    "message_no_permission": "권한이 없습니다.",
    "message_sms_sended": "인증번호를 보내드렸어요",
    "message_sms_error": "다시 시도해주세요",
    "message_email_sended": "인증번호를 보내드렸어요. 메일함을 확인하세요",
    "message_email_error": "이메일이 발송되지 않았어요, 다시 시도해 주세요",
    "message_verify_success": "인증이 완료되었어요!",
    "message_verify_fail": "인증 실패",
    "message_code_invalid": "인증 코드가 잘못되었거나 만료되었습니다!",
    "tooltip_developing": "업데이트 중이에요, \n조금만 기다려주세요:)",
    "not_found_title": "찾는 페이지가 없습니다.",
    "not_found_tips": "잘못된 경로로 들어왔습니다.",
    "empty_no_data": "데이터가 없습니다.",
    "empty_on_dev": "준비 중입니다.",
    "upgrading_tip": "Coming Soon",
    "upload_avatar": "프로필 사진",
    "device_reminder_content": "adobaro는 데스트탑 환경에 최적화되어 있습니다. 모바일 기기에서 접속시 일부 기능이 제한 될 수 있습니다. ",
    "trial_watermark": "adobaro 체험판",
    "platform_hao_kan": "하오칸 비디오",
    "platform_tencent": "텐센트 비디오",
    "platform_iqiyi": "아이치이",
    "platform_bilibili": "비리비리",
    "platform_xi_gua": "시과 비디오",
    "platform_wei_bo": "웨이보",
    "platform_youtube": "유튜브",
    "platform_dou_yin": "도우인",
    "platform_red_book": "샤오홍슈",
    "platform_kuai_shou": "콰이쇼우",
    "platform_cloud_music": "왕이윈뮤직",
    "platform_wechat_video": "웨이신스핀하오",
    "platform_mei_pai": "메이파이",
    "platform_da_yu": "따위하오",
    "platform_dian_tao": "디엔타오",
    "platform_qq_world": "큐큐월드",
    "platform_tiktok": "틱톡",
    "platform_instagram": "인스타그램",
    "platform_twitter": "트위터",
    "country_USA": "미국",
    "country_CAN": "캐나다",
    "country_KRW": "한국",
    "country_CHN": "중국"
  },
  "dashboard": {
    "home": "대시보드",
    "members": "멤버관리",
    "wallet": "내 지갑",
    "setting": "설정",
    "close_sidebar": "메뉴 접기",
    "customer_service": "고객센터",
    "lang_language": "언어",
    "lang_zh_cn": "中文(简体)",
    "lang_ko_kr": "한국어",
    "lang_en_eu": "English",
    "sign_out": "로그아웃"
  },
  "sign": {
    "signin_intro_title": "You Create, \nWe Navigate. ",
    "signin_intro_mcn": "MCN, 에이전시를 위한 관리 솔루션",
    "signin_intro_mcn_subtitle": "소속 크리에이터의 수 많은 채널들\r\n이젠 한 번에 케어.",
    "signin_intro_operation": "안전하고 스마트한 콘텐츠 운영",
    "signin_intro_operation_subtitle": "AI로 더욱 똑똑하고 빠르게 실행하는\n해외 플랫폼 정책 맞춤형 콘텐츠 자동 최적화 ",
    "signin_login_title": "로그인",
    "signin_enter_password": "비밀번호 입력",
    "password_check_1": "8~20 글자",
    "password_check_2": "영문 대/소문자 포함",
    "password_check_3": "숫자, 특수문자 1개 이상 포함",
    "password_incorrect_format": "일치하지 않는 비밀번호입니다",
    "signin_title": "Welcome! ",
    "signin_subtitle": "MCN, 에이전시를 위한 관리 솔루션\n\r\n소속 크리에이터의 수 많은 채널들\r\n이젠 한번에 케어.",
    "email_holder": "이메일을 입력하세요",
    "email_error": "잘못된 이메일 형식이에요",
    "forgot_password": "비밀번호 찾기",
    "signup": "회원가입",
    "signup_tip": "아직 계정이 없으신가요? {0} 하기",
    "account_not_exist": "입력하신 계정정보를 찾을 수 없어요.\n다시 확인해 주세요. ",
    "signin_error": "로그인 실패",
    "google_signin_error": "구글 로그인 실패",
    "signin_warning": "해외IPㆍ보안 이슈가 감지되었습니다.\n이메일 본인인증을 진행해주세요.",
    "signin_fail": "잘못된 비밀번호입니다",
    "too_many_requests": "잠시 후 다시 시도해 주세요.",
    "modal_signup_tips1": "비밀번호 설정이 가능한 링크를 이메일로 보내드렸어요.",
    "modal_signup_tips2": "메일함을 열어 비밀번호 설정을 완료해주세요",
    "modal_signup_tips3": "*이메일을 받지 못 하셨다면 스팸함을 확인해주세요.",
    "modal_signup_btn": "네, 이메일을 확인할게요",
    "modal_welcome_tips1": "아도바로 엔터프라이즈에 오신걸 환영합니다! ",
    "modal_welcome_tips2": "관리자가 확인 후 가입에 대한 승인 처리를 진행할 예정입니다.",
    "modal_welcome_tips3": "진행 결과는 이메일을 통해 전송되오니 메일을 참고해 주세요.",
    "modal_welcome_btn": "네, 확인했어요",
    "modal_forgot_title": "비밀번호 찾기",
    "modal_forgot_tips1": "비밀번호 재설정 링크가 첨부된 이메일을 보내드릴게요",
    "modal_forgot_tips2": "아래 버튼을 클릭하고, 메일함을 확인해주세요",
    "modal_forgot_tips3": "*메일을 받지 못하셨나요? 스팸 메일함도 확인해주세요.",
    "modal_forgot_btn": "비밀번호 재설정 시작하기",
    "modal_wait_title": "계정 미승인 상태",
    "modal_wait_tips1": "관리자가 계정을 검수하고 있습니다.",
    "modal_wait_tips2": "진행결과는 이메일을 통해 안내되오니, 메일을 참고해 주세요.\r\n\r\n",
    "modal_wait_tips3": "시일이 많이 소요되는 경우 아래 문의하기 버튼을 통해 \n담당자에게 문의해 주세요.",
    "modal_wait_btn": "문의하기",
    "forgot_email_send": "인증 메일이 발송되었습니다.",
    "forgot_email_send_error": "이메일 발송 실패",
    "verify_success": "인증 성공",
    "verify_fail": "인증 실패",
    "verify_success_tip": "인증 절차가 완료되었습니다. ",
    "verify_fail_tip": "링크 오류 또는 인증 유효시간이 초과되었습니다. ",
    "btn_signin_retry": "다시 로그인하기",
    "signup_intro_title": "You Create, \nWe Navigate. ",
    "account_exist": "이미 가입된 계정입니다.",
    "license_no_holder": "사업자등록번호를 입력하세요",
    "invitation_code_holder": "가입코드를 입력하세요",
    "license_or_code_error": "사업자등록번호/가입코드 오류입니다.\n다시 확인해주세요",
    "invitation_code_tip": "가입코드를 받지 못하셨나요? {0}",
    "invitation_code_tip_text": "가입코드 요청하기",
    "btn_signup": "회원가입",
    "signup_age_14": "만 14세 이상입니다.",
    "signup_agree_tips": "아도바로 회원가입 시 {0} 및 {1}에 동의합니다",
    "signup_agree_tips_terms": "이용약관",
    "signup_agree_tips_privacy": "개인정보 처리방침",
    "signup_marketing_protocol": "(선택) 마케팅 정보 수신 동의서",
    "signin": "로그인",
    "signin_tip": "이미 계정이 있으신가요? {0}",
    "signup_email_send": "본인인증 메일을 보내드렸어요. \n이메일에서 다음 절차를 진행해주세요.",
    "signup_email_send_error": "가입 실패",
    "password_intro_title": "You Create, \nWe Navigate. ",
    "password_set": "비밀번호 설정",
    "password_reset": "신규 비밀번호 설정",
    "password_set_subtitle": "이메일 인증을 통해 본인 인증이 완료되었습니다.\n안전한 비밀번호를 설정해 주세요.",
    "password_reset_subtitle": "이메일 인증을 통해 본인 인증이 완료되었습니다.\n안전한 비밀번호로 재설정해 주세요.",
    "btn_password_set": "비밀번호 설정",
    "btn_password_reset": "비밀번호 재설정",
    "password_set_success": "비밀번호 설정이 완료되었어요",
    "password_reset_success": "비밀번호 재설정이 완료되었어요",
    "password_set_fail": "비밀번호 설정에 실패했어요",
    "password_reset_fail": "비밀번호 재설정에 실패했어요",
    "password_set_code_invalid": "인증코드 유효시간이 만료되었습니다. \n회원가입 절차를 다시 진행하세요.",
    "password_reset_code_invalid": "인증코드 유효시간이 만료되었습니다. \n비밀번호 재설정 절차를 다시 진행하세요. "
  },
  "home": {
    "invite_label": "멤버 초대",
    "total_platform_overview_title": "플랫폼 전체 현황",
    "total_members": "총 멤버",
    "total_platform_fans": "전체 팔로워 합계",
    "total_platform_plays": "전체 조회수 합계",
    "check_more": "더 알아보기",
    "add_member": "멤버 초대",
    "earnings_overview_title": "수익 현황",
    "total_earnings": "누적 수익",
    "settled_earnings": "정산 수익",
    "balance_earnings": "수익 잔액",
    "total_tips": "누적 획득 수익",
    "settled_tips": "누적 정산 수익",
    "balance_tips": "미정산 수익 잔액",
    "tour_step1": "버튼을 클릭하여 구성원을 초대할 수 있습니다.",
    "tour_step2": "UID를 통해 초대할 멤버를 검색할 수 있어요",
    "tour_step3": "내 멤버 구성원의 정보를 확인할 수 있어요",
    "tour_step4": "멤버 구성원, 팔로워 및 조회수 등 정보를 확인할 수 있어요",
    "tour_step5": "수익, 정산금액, 잔액 등 정보를 확인할 수 있어요",
    "settle_title": "소속 멤버 구성원들은 Pro 혜택을 받아요.\n다음달 예상 이용료는 소속 멤버의 인원수에 따라 변동됩니다.",
    "settle_clause": "- 첫 달 이용료 : 기본 이용료(최소 이용료) $490\n- 멤버 구성원이 10명을 초과하는 경우, $49/1명의 요금제 적용\n- 멤버 구성원 규모에 따라 할인율이 적용됩니다. 자세한 할인정보는 아래를 참고하세요",
    "settle_current_members": "현재 멤버수:",
    "settle_discount_rate": "할인율:",
    "settle_estimate_next_amount": "예상 결제 금액:",
    "settle_estimate_tips": "결제금액은 최종 확정된 금액을 기준으로 합니다",
    "settle_discount_btn": "할인율 자세히 알아보기",
    "settle_example_label": "예)",
    "settle_example1_date": "1월 15일",
    "settle_example1_content": "최초 가입시 첫달 이용료 $490",
    "settle_example2_date": "다음 결제일까지 D-7일 남았어요",
    "settle_example2_content": "소속 멤버의 인원수에 따라 다음달 결제 금액이 확정돼요",
    "settle_example3_date": "2월 15일",
    "settle_example3_content": "다음 결제일",
    "settle_last_amount": "지난 결제일\n결제 금액:",
    "settle_next_amount": "다음 결제 금액",
    "settle_D_7_members": "D-7일 멤버 수:",
    "settle_register_btn": "엔터프라이즈 파트너 가입",
    "settle_help_center_link": "자세한 정보는 {헬프센터}를 확인해주세요",
    "beta_modal_title": "현재 베타서비스 중으로 결제 관련 문의는 담당자와 협의해 주세요.",
    "discount_member": "멤버 구성원",
    "discount_rate": "할인율",
    "discount_amount": "확정 금액",
    "discount_each_person": "{0}/1명",
    "discount_more_then": "{0} 이상",
    "discount_negotiate": "별도 문의를 통해 확인하세요"
  },
  "member": {
    "search_label": "내 멤버 검색",
    "search_placeholder": "멤버 UID를 검색하세요",
    "invite_btn": "초대하기",
    "add_placeholder": "초대하려는 멤버의 UID를 정확하게 입력하세요",
    "fans_number": "팔로워:",
    "add_invite": "초대하기",
    "add_cooperation": "초대 완료된 멤버입니다",
    "add_invited": "초대 완료",
    "add_other": "타 그룹에 소속된 멤버",
    "add_undefined": "존재하지 않는 계정이에요. 다시 확인해주세요",
    "add_modal_title": "멤버 초대",
    "invite_success": "멤버 초대 요청을 보냈어요!",
    "invite_fail_cooperated": "이미 다른 파트너사와 함께하고 있는 크리에이터에요",
    "invite_fail": "오류가 발생했어요, 다시 시도해 주세요!",
    "cancel_success": "요청을 취소했어요",
    "cancel_fail": "오류가 발생했어요, 다시 시도해 주세요!",
    "no_members": "아직 멤버가 없어요, 멤버 UID를 검색해서 그룹에 초대해 주세요",
    "search_empty": "찾을 수 없는 멤버에요, 다시 시도해주세요",
    "radio_all": "전체",
    "radio_fans": "팔로워 많은 순",
    "radio_plays": "조회수 높은 순",
    "radio_alphabet": "가나다 순",
    "total_member": "멤버 총{0}명",
    "account_name": "계정명",
    "invite_status": "초대 상태",
    "open_platform": "채널 개설",
    "total_subscriptions": "팔로워",
    "total_plays": "조회수",
    "total_income": "수익",
    "action": "실행",
    "invite_status_success": "초대 성공",
    "invite_status_wait": "초대 수락 대기중",
    "invite_status_refuse": "초대가 거절되었어요",
    "invite_status_cancel": "초대가 취소되었어요",
    "invite_cancel_btn": "초대 취소하기",
    "invite_again_btn": "다시 초대하기",
    "modal_cancel_title": "정말 초대를 취소할까요?",
    "modal_cancel_content": "취소된 초대는 복구가 어려워요. 신중하게 진행해주세요!",
    "modal_delete_title": "정말 삭제할까요?",
    "modal_delete_content": "삭제한 이후에는 복구가 어려워요. 신중하게 진행해주세요",
    "modal_invite_title": "초대 코드를 보내시겠어요?",
    "modal_invite_content": "초대하려는 대상이 귀사의 소속 크리에이터가 맞는지 확인해주세요. \n소속 크리에이터가 아닌 대상을 초대한 경우, 발생 가능한 모든 문제의 책임은 귀사에게 있음을 알려드립니다.  ",
    "modal_ok_invite": "초대하기",
    "modal_cancel_next": "취소",
    "opened_platforms": "개설 채널",
    "platform_management": "더보기",
    "all_platforms_fans": "전체 팔로워",
    "all_platforms_plays": "전체 조회수",
    "all_data_statistic": "{0}전체 플랫폼 데이터 통계",
    "upload_video": "업로드",
    "upload_video_tips": "아직 채널이 개설되지 않았어요,\n멤버 계정에서 채널 개설을 먼저 진행해주세요.",
    "latest_publish": "최신 업로드",
    "earnings_overview": "수익 개요",
    "earnings_total_amount": "누적 수익",
    "earnings_withdrawal_amount": "누적 출금액",
    "earnings_balance_amount": "계정 잔액",
    "earnings_detail": "수익 상세",
    "platform_title": "플랫폼 요약",
    "platform_info_title": "기본 정보",
    "platform_info_avatar": "프로필 사진",
    "platform_info_intro": "채널 소개",
    "platform_info_intro_empty": "채널 소개가 없습니다.",
    "platform_info_intro_holder": "채널 소개를 입력해 주세요.",
    "platform_info_date": "채널 가입일",
    "platform_info_date_empty": "데이터가 없습니다.",
    "platform_info_date_holder": "채널 가입 시간을 선택해 주세요.",
    "platform_info_classify": "채널 카테고리",
    "platform_info_classify_empty": "데이터가 없습니다.",
    "platform_info_classify_holder": "카테고리를 선택해 주세요.",
    "platform_info_income": "플랫품 수익",
    "platform_info_order": "광고",
    "platform_info_btn_again": "재추천",
    "platform_info_btn_open": "바로 개통하기",
    "platform_info_btn_edit": "기본 정보 수정",
    "platform_info_btn_submit": "저장 및 수정",
    "platform_data_overview_title": "데이터 요약",
    "platform_data_radio_yesterday": "어제",
    "platform_data_radio_nearly7": "최근 7일",
    "platform_data_radio_nearly30": "최근 30일",
    "platform_data_fans": "누적 구독자 수",
    "platform_data_likes": "누적 좋아요 수",
    "platform_data_platform": "누적 플랫폼 수익",
    "platform_data_published": "누적 배포 수",
    "platform_data_plays": "누적 조회 수",
    "platform_data_ad": "누적 광고 수익",
    "platform_release_video_title": "배포된 콘텐츠",
    "content_management": "콘텐츠 관리",
    "content_btn_all": "전체",
    "content_btn_play_most": "높은 조회수 순",
    "content_btn_like_most": "높은 좋아요 순",
    "content_detail_title": "콘텐츠 상세보기",
    "content_base_info": "기본 정보",
    "content_base_video": "영상",
    "content_base_cover": "썸네일",
    "content_base_title": "제목",
    "content_base_subtitle": "자막",
    "content_base_upload_date": "업로드시간",
    "content_empty_cover": "썸네일이 없네요",
    "content_mcn_upload": "MCN업로드",
    "content_no_data": "원본파일이 삭제되었습니다.",
    "content_under_review": "AI 콘텐츠 자동 검수가 진행중이에요, 잠시만 기다려주세요",
    "content_in_failure": "AI 검수 결과, 아래 항목 개선이 필요해요. \n수정을 완료하신 다음,  다시 업로드 해 주세요",
    "content_distributed_platform": "업로드 현황",
    "content_distributed_name": "플랫폼",
    "content_distributed_state": "상태",
    "content_distributed_subtitle": "자막",
    "content_distributed_plays": "조회 수",
    "content_distributed_likes": "좋아요 수",
    "content_distributed_upload_date": "업로드 시간",
    "content_distributed_action": "관리",
    "content_status_playing": "업로드 성공",
    "content_status_opening": "검토중",
    "content_status_failure": "업로드 실패",
    "content_status_unpublished": "업로드 가능",
    "content_status_not_opened": "채널 없음",
    "content_status_frozen": "잠김",
    "content_status_deleting": "삭제 중",
    "content_status_deleted": "삭제 완료",
    "content_action_operate": "링크",
    "content_action_ask": "문의하기",
    "content_action_publish": "업로드",
    "content_action_waiting_open": "채널 개설 대기중",
    "content_action_check": "보기",
    "content_action_analysis": "분석",
    "content_action_edit": "수정",
    "content_action_delete": "삭제",
    "content_action_unfreeze": "잠김 해제",
    "content_delete_video_title": "삭제 신청한 영상은 복구가 어려워요. 정말 삭제하시곘어요?",
    "content_delete_video_success": "삭제 신청이 접수되었어요!",
    "content_delete_video_error": "삭제 신청에 실패했어요.",
    "content_republish_success": "성공적으로 업로드 되었습니다.",
    "content_republish_error": "업로드에 실패했습니다.",
    "content_republish_error_insufficient": "동영상 업로드 실패. 업로드 가능횟수 초과",
    "content_republish_can": "{0} 채널에 추가로 업로드 하시겠습니까? \n잔여 횟수: {1}회",
    "content_republish_cannot": "{0}플랫폼에 게시 가능한 영상의 남은 횟수가 {1}회이므로 영상을 제출할 수 없습니다.",
    "content_republish_certify_warning": "인증 완료후에 업로드 가능한 플랫폼입니다. 인증을 먼저 완료해주세요.",
    "content_video_url_error": "업로드 링크를 찾지 못 했습니다.",
    "content_failure_reason": "실패 이유",
    "content_distributed_state_intro": "업로드 성공 : 콘텐츠 심사 업로드가 완료된 상태입니다.\n검토중 : 각 플랫폼별 콘텐츠 검토 및 업로드를 위한 필요 절차를 진행하는 단계입니다.\n업로드 가능 : 채널이 개설되어 있으며 해당 콘텐츠 업로드 신청이 가능한 상태입니다.\n채널 없음 : 채널이 아직 개설되지 않은 상태입니다."
  },
  "platform": {
    "guide_title": "채널 분석",
    "guide_subtitle": "본인의 채널ID를 입력하시면, AI분석을 통해 최적의 플랫폼을 추천해 드립니다.",
    "guide_platform_holder": "플랫폼을 선택해 주세요.",
    "guide_platform_url_holder": "채널 ID를 입력해 주세요.",
    "guide_platform_url_error": "채널 ID를 다시 확인해 주세요.",
    "guide_platform_already": "이미 등록된 플랫폼 채널입니다.",
    "guide_platform_confirm": "채널 정보를 제출하면, 당분간 수정이 불가능합니다.\n입력된 정보가 맞다면 \"확인\" 을 눌러주세요",
    "guide_platform_tip": "채널ID는 어디에서 확인할 수 있나요?",
    "guide_btn_recommend": "플랫폼 추천",
    "guide_platform_error": "플랫폼 추천 실패",
    "guide_skip_title": "나중에 하기",
    "guide_skip_subtitle": "나중에 하시려는 이유가 무엇인지 알려 주세요.",
    "guide_skip_holder": "이유를 선택해 주세요.",
    "guide_skip_reason_1": "운영 채널이 없습니다.",
    "guide_skip_reason_2": "이미 개통한 채널이 있습니다.",
    "guide_skip_reason_3": "원하는 국가 플랫폼이 없습니다.",
    "guide_skip_reason_4": "기타 이유",
    "guide_skip_btn": "메인으로 가기",
    "guide_analysis_title": "분석중",
    "guide_analysis_subtitle": "채널 분석을 통해 최적은 플랫폼을 추천해 드립니다.",
    "guide_analysis_fail_title": "현재 이용자가 많습니다.",
    "guide_analysis_fail_subtitle": "분석이 완료된 후 이메일로 알려 드릴께요.",
    "guide_analysis_fail_btn_next": "다음에 할께요.",
    "guide_analysis_fail_btn_confirm": "알려 주세요.",
    "guide_analysis_done_title": "채널 분석 완료",
    "guide_analysis_done_subtitle": "앞으로 업로드하는 콘텐츠는 플랫폼에 자동으로 최적화됩니다. ",
    "guide_analysis_platform_about": "플랫폼 이해돕기",
    "guide_analysis_btn_open": "개설 신청",
    "guide_analysis_btn_already": "기존 채널 연결하기",
    "guide_analysis_btn_next": "다음에 할게요",
    "guide_recommend_fail": "플랫폼 개통 실패",
    "guide_bind_title": "채널 연동",
    "guide_bind_subtitle": "이미 개통한 채널이 있다면, 아도바로에 연동해 보세요~\n모든 플랫폼을 한 번에 관리하실 수 있습니다.",
    "guide_bind_tip": "왜 비밀번호를 입력해야 하나요?",
    "guide_bind_btn_confirm": "채널 연동하기",
    "guide_bind_btn_next": "다음에 연동할게요.",
    "guide_bind_success": "플랫폼 개통이 신청되었습니다. 승인 여부는 이메일을 통해 안내되오니, 메일을 확인해 주세요.",
    "guide_open_phone_title": "번호 등록",
    "guide_open_phone_subtitle": "본인 명의의 휴대폰 번호를 입력해 주세요.",
    "guide_enter_phone": "휴대폰 번호를 입력해 주세요",
    "guide_open_btn_verify": "다음",
    "guide_open_send_sms_error": "인증 발송 실패",
    "guide_open_verify_title": "인증번호 발송",
    "guide_open_verify_subtitle": "인증번호 발송 버튼을 클릭하시면 인증번호가 발송됩니다. 시스템 상태에 따라 인증번호 수신이 지연될 수 있으니, 잠시 기다려 주십시오.수신문자예시:",
    "guide_open_btn_verifying": "인증하기",
    "guide_open_verify_error": "인증에 실패했습니다.",
    "manage_title": "채널 관리",
    "manage_subtitle": "(✿◡‿◡)쉽고 안전한 채널 개설, 바로 아도바로에서 시작하세요. 채널개설과 인증에 필요한 모든 복잡한 과정들을 아도바로에서 정확하고 안전하게 진행해 드립니다. ✌️' ֊' ",
    "applied_platform": "채널 개설 현황",
    "btn_refresh": "데이터 갱신",
    "btn_refresh_tip": "클릭 시 채널개설 현황이 갱신됩니다.",
    "pending_tip_content": "입력하신 휴대폰 번호로 플랫폼 개통이 진행될 예정입니다. 새로고침 버튼을 눌러 하단에 \"인증번호 발송\" 버튼이 생성되면, 클릭 후 인증을 진행해 주세요.",
    "btn_all": "전체",
    "btn_play_most": "높은 조회수 순",
    "btn_like_most": "높은 좋아요 순",
    "platform_empty": "채널 개설을 시작해 보세요.",
    "platform_frozen_tip": "보기 또는 잠금해제 클릭",
    "platform_view_btn": "플랫폼 보기",
    "status_done": "개설/인증 완료",
    "status_opening": "개설중",
    "status_verifying": "인증 중입니다. 잠시만 기다려 주세요.",
    "status_verify_failed": "인증 실패",
    "status_failed": "개통 실패",
    "status_unbind": "개설완료, 인증중",
    "status_binding": "개설승인 완료, 인증 진행중",
    "status_bound_fail": "인증오류",
    "btn_status_done": "업로드",
    "btn_status_failed": "자세히 보기",
    "status_opening_intro": "개설중 : 채널 개설에 필요한 절차를 진행하는 단계입니다.",
    "status_unbind_intro_1": "개설완료, 인증중 : 채널 개설 절차가 완료되었으며, 신분인증, 소유권인증, 채널인증, 적합성 검사 등 각 플랫폼 규정에 따라 필수 인증 절차를 진행하는 단계입니다. ",
    "status_unbind_intro_2": "인증을 완료하기 위해 첫 업로드 진행이 필요합니다.",
    "status_unbind_intro_3": "*플랫폼에 따라 총 5-30일 정도 소요될 수 있습니다.",
    "status_done_intro": "개설/인증 완료: 채널 개설 및 인증 절차가 모두 완료된 단계입니다.",
    "un_apply_platform": "신청 가능 플랫폼",
    "open_count": "{0} 명의 유저가 이미 개통했습니다.",
    "lastest_open": "유저 {0} {1} 개통",
    "btn_open": "개설 신청",
    "btn_reapply": "재신청",
    "open_platform_title": "채널 개설",
    "btn_contact_us": "문의하기",
    "platform_recommend": "추천",
    "platform_disable": "점검중",
    "platform_disable_title": "알림",
    "platform_disable_content": "{0} 플랫폼 시스템 점검으로 인해 채널 개설 서비스가 일시중단 되었습니다. 문의사항이 있으시면 고객센터로 문의해 주시기 바랍니다.🧏‍♀️",
    "platform_conditions_required": "🚨 신청 불가: 플랫폼 개설 신청 조건을 충족하지 못했습니다. 신청 조건을 확인 후 다시 시도해 주세요.",
    "modal_bound_fail_title": "인증에 실패했습니다. 👀",
    "modal_bound_fail_content": "아래 내용을 확인해 주세요\n\n⚠️원인: {0}",
    "modal_bound_fail_btn": "인증 완료하기",
    "open_platform_subtitle": "채널개설과 인증에 필요한 모든 복잡한 과정들을 아도바로에서 정확하고 안전하게 진행해 드립니다. ",
    "details_title": "플랫폼 요약",
    "details_info_title": "기본 정보",
    "details_info_avatar": "프로필 사진",
    "details_info_intro": "채널 소개",
    "details_info_intro_empty": "채널 소개가 없습니다.",
    "details_info_intro_holder": "채널 소개를 입력해 주세요.",
    "details_info_date": "채널 가입일",
    "details_info_date_empty": "데이터가 없습니다.",
    "details_info_date_holder": "채널 가입 시간을 선택해 주세요.",
    "details_info_classify": "채널 카테고리",
    "details_info_classify_empty": "데이터가 없습니다.",
    "details_info_classify_holder": "카테고리를 선택해 주세요.",
    "details_info_income": "플랫품 수익",
    "details_info_order": "광고",
    "details_info_btn_again": "재추천",
    "details_info_btn_open": "바로 개통하기",
    "details_info_btn_edit": "기본 정보 수정",
    "details_info_btn_submit": "저장 및 수정",
    "details_data_overview_title": "데이터 요약",
    "details_data_radio_yesterday": "어제",
    "details_data_radio_nearly7": "최근 7일",
    "details_data_radio_nearly30": "최근 30일",
    "details_data_fans": "누적 구독자 수",
    "details_data_likes": "누적 좋아요 수",
    "details_data_platform": "누적 플랫폼 수익",
    "details_data_published": "누적 배포 수",
    "details_data_plays": "누적 조회 수",
    "details_data_ad": "누적 광고 수익",
    "details_release_video_title": "배포된 콘텐츠",
    "trial_modal_title": "adobaro 체험판",
    "trial_modal_subtitle": "아도바로 체험판을 이용하시기 전에 꼭 읽어주세요!",
    "trial_modal_btn_agree": "체험하러 가기",
    "trial_protocol_title": "위 내용을 숙지하여 주시고, 즐거운 체험되세요.",
    "trial_msg_toggle_error": "체험판 진입에 실패하였습니다.",
    "trial_msg_certified_error": "이미 Global Pass 인증이 통과되어 체험판 사용이 불가합니다.",
    "trial_protocol_item_1": "체험버전을 이용하는 모든 분들의 콘텐츠가 공유되니, 업로드할 콘텐츠에 주의해주세요.",
    "trial_protocol_item_2": "체험버전에서 업로드된 콘텐츠는 다음날 모두 초기화 됩니다.",
    "trial_protocol_item_3": "체험버전에 업로드된 콘텐츠의 데이터는 체험버전을 위해서 만들어진 채널의 실제 데이터입니다.",
    "trial_protocol_item_4": "체험버전의 모든 기능은 정식버전의 기능과 동일합니다.",
    "trial_protocol_item_5": "원활한 체험을 위해 일부 기능은 지원되지 않습니다.",
    "trial_protocol_subtitle_2": "위 내용을 숙지하여 주시고, 즐거운 체험되세요.",
    "guide_exit_opening_platform": "현재 프로세스를 종료하면 입력한 정보가 손실되며, 다음 개통 신청 시 처음부터 다시 진행됩니다.",
    "guide_exit_cancel": "취소",
    "guide_exit_confirm": "확인",
    "guide_submit_success": "제출 성공",
    "guide_waiting_phone_code": "등록은 3~5분 후 완료될 예정이니 기다려 주세요.",
    "guide_know_btn": "알겠습니다.",
    "guide_change_account": "채널 변경",
    "guide_phone_code_verify": "인증번호 인증",
    "guide_pass_verify": "여권 검증",
    "guide_pass_code_verify": "인증번호 재인증",
    "guide_verify_fail": "인증 실패",
    "guide_click_verify": "검증 신청",
    "guide_check_fail_reason": "실패 원인 보기",
    "pending_tips": "플랫폼 오픈 중이니 새로고침 버튼을 누르시면, 바로 최신 정보를 받으실 수 있습니다. ",
    "doing_warning": "5~10초 뒤 다시 시도해 주세요.",
    "guide_open_submit_success": "휴대폰 번호가 입력되었습니다.",
    "guide_open_verify_success": "인증번호가 제출되었습니다.",
    "guide_click_send": "인증번호 전송",
    "guide_waiting_pass_code": "플랫폼 개통은 영업일 기준 1~2일이 소요됩니다. 조금만 기다려 주세요.",
    "guide_submit_phone_failed": "시스템 처리에 실패하였습니다. 잠시 후 다시 시도해주세요.",
    "have_opened": "개통완료",
    "guide_open_security": "플랫폼 자체 심사로 인해 개통에 시간이 필요한 플랫폼입니다(평균 2주). 다음 단계 준비가 완료되면 이메일과 알림이 발송됩니다.",
    "guide_get_code_error": "시스템 오류입니다. 잠시 후 다시 시도해 주세요.",
    "bilibili_tips_1": "비리비리 플랫폼은 \"중국판 유튜브\"로 널리 알려져 있으며, 특히 18-35세의 MZ세대 사이에서 큰 인기를 끌고 있습니다. 💖",
    "bilibili_tips_2": "이 플랫폼의 주 사용자층은 24세 이하로, 전체 사용자의 75%를 차지하고 있습니다. 또한, 비리비리는 월간 활성 이용자 수가 약 3억 15만 명에 달하고, 약 380만 명의 크리에이터가 활동 중입니다. 🏆",
    "bilibili_tips_3": "가로 및 세로 형식의 영상 모두를 지원하는 이 플랫폼은, 화면을 가로지르는 독특한 탄막(弹幕) 기능으로 젊은층에게 매력적인 디지털 콘텐츠 플랫폼으로 자리 잡고 있습니다.",
    "bilibili_tips_4": "- Global Pass 인증",
    "xiaohongshu_tips_1": "샤오홍슈 플랫폼은 \"중국판 인스타그램\"으로 알려져 있으며, 18-34세 여성들에게 인기가 높습니다.💖 월간 활성 이용자 수는 2억 6천만 명에 이르며, 약 2천만 명의 크리에이터가 활동 중입니다.🏆",
    "xiaohongshu_tips_2": "가로 및 세로 형식의 영상이 지원하며, 소셜네트워크와 전자상거래 기능을 결합한 형태로, 사용자들은 제품 리뷰, 여행지 소개 등을 텍스트와 동영상 형태로 공유합니다. 바둑판식 레이아웃이 특징인 샤오홍수는 젊은 사용자들에게 매력적인 콘텐츠를 제공하며, 이를 통해 강력한 바이럴 마케팅 효과를 발휘합니다.",
    "xiaohongshu_tips_3": "- Global Pass 인증",
    "kuaishou_tips_1": "\"중국판 틱톡\"으로 불리는 콰이쇼우는 주로 18세 이하의 10대들에게 인기가 높으며, 월간 활성 이용자 수가 6억 명에 달합니다.💖 200만 명 이상의 크리에이터가 활동하고 있으며, 주로 세로 형식의 영상을 지원합니다.🏆",
    "kuaishou_tips_2": "콰이쇼우는 서정적인 일상과 농촌 생활을 다루는 콘텐츠로 유명하며, 라이브커머스를 통한 매출이 높은 편입니다. 최근에는 젊은 사용자층이 늘어나고 있으며, 콘텐츠를 업로드 주기가 매우 중요한 플랫폼입니다.",
    "kuaishou_tips_3": "- Global Pass 인증",
    "iqiyi_tips_1": "아이치이는 \"중국판 넷플릭스\"로 불리며, 다양한 연령대의 사용자들이 이용하는 플랫폼입니다.💖 월간 활성 이용자 수는 5억 4천만 명에 달하며, 300만 명 이상의 크리에이터들이 활동하고 있습니다. 주로 가로 형식의 영상을 지원합니다.🏆",
    "iqiyi_tips_2": "아이치이는 드라마와 버라이어티쇼 등 다양한 글로벌 콘텐츠도 제작하고 있으며 비디오 서비스 플랫폼으로는 전세계적에서 넷플릭스 가장 많은 유료 구독자를 보유하고 있습니다.",
    "iqiyi_tips_3": "- Global Pass 인증",
    "haokan_tips_1": "하오칸 비디오는 \"중국판 네이버\"로 불리며, 주로 26-35세의 사용자층을 대상으로 합니다.💖 이 플랫폼의 월간 활성 이용자 수는 약 1억 1천만 명이며, 200만 명 이상의 크리에이터들이 활동 중입니다. 주로 가로 형식의 영상을 지원합니다.🏆",
    "haokan_tips_2": "하오칸 비디오는 중국 최대 검색엔진인 바이두의 대표적인 중편 영상 플랫폼으로, 정부의 영향을 많이 받아 비교적 보수적인 운영 정책을 가지고 있습니다.",
    "haokan_tips_3": "- Global Pass 인증",
    "haokan_tips_4": "- 중국 플랫폼 1개 이상 채널 개설 및 인증 완료",
    "haokan_tips_5": "- 유튜브, 틱톡 또는 중국 채널에 본인 얼굴이 출연하는 영상 1개 이상 보유 (*100만 구독자 이상 채널의 경우, 본인 얼굴 출연 영상 없이 특별심사 진행 가능)",
    "qqworld_tips_1": "\"중국판 카카오 스토리\"로 불리는 QQ World 플랫폼는 중국판 카카오톡(QQ)과 카카오스토리의 결합 형태로, 특히 1995년 이후 출생한 젊은 사용자들 사이에서 인기가 높습니다.💖 이 플랫폼의 월간 활성 이용자 수는 약 3억 5천만 명이며, 120만 명 이상의 크리에이터가 활동 중입니다. QQ는 가로 및 세로 형식의 영상을 지원하며, 조회수에 따른 수익은 제공하지 않습니다.🏆",
    "qqworld_tips_2": "주로 학생들을 포함한 젊은 연령대의 사용자들이 많아, 현대적이고 다양한 콘텐츠가 활발하게 공유됩니다.",
    "qqworld_tips_3": "- Global Pass 인증",
    "qqworld_tips_4": "- 중국 플랫폼 1개 이상 채널 개설 및 인증 완료\r",
    "xigua_tips_1": "시과 비디오 플랫폼은 \"중국판 유튜브\"로 알려져 있으며, 25-35세 사용자들이 45%를 차지하며, 남성과 여성의 비율이 비슷합니다. 💖월간 활성 이용자 수는 1억 8천만 명에 달하며, 약 320만 명의 크리에이터가 활동 중입니다. 주로 가로 형식의 영상을 지원합니다.🏆",
    "xigua_tips_2": "바이트댄스의 개인 맞춤형 콘텐츠 추천 알고리즘을 통해 넓은 사용자층과 다양한 콘텐츠 영역을 확보한 시과는 특히 브이로그와 '3농'(농업, 농촌, 농민) 분야의 콘텐츠에 대한 관심이 높습니다. 또한, 도우인 플랫폼과의 연계를 통해 시너지 효과를 발휘하며 빠른 성장을 기대할 수 있습니다.",
    "xigua_tips_3": "- Global Pass 인증",
    "xigua_tips_4": "- 중국 플랫폼 1개 이상 채널 개설 및 인증 완료\r",
    "bilibili_need_time": "예상 소요 기간 : 평균 1-2 영업일",
    "xiaohongshu_need_time": "예상 소요 기간 : 평균 2-3 영업일",
    "kuaishou_need_time": "예상 소요 기간 : 평균 2-3 영업일",
    "iqiyi_need_time": "예상 소요 기간 : 평균 2-3 영업일",
    "haokan_need_time": "예상 소요 기간 : 평균 10-15 영업일",
    "qqworld_need_time": "예상 소요 기간 : 평균 5-10 영업일",
    "xigua_need_time": "예상 소요 기간 : 평균 5-10 영업일",
    "guide_waiting_open_title": "채널 개설 신청이 완료되었습니다.🎊",
    "guide_waiting_open_subtitle": "채널개설과 인증에 필요한 모든 복잡한 과정들을 아도바로에서 대신하여 정확하고 안전하게 진행해 드립니다. 🔐",
    "guide_waiting_open_tip": "*기존 채널 운영 현황에 따라 개설 및 인증 과정과 소요 시간이 다를 수 있습니다.💖",
    "application_requirement": "⚠️신청조건",
    "guide_introduction": "플랫폼 소개"
  },
  "auth": {
    "modal_title": "adobaro에 오신걸 환영합니다.",
    "modal_subtitle": "나의 채널을 가장 쉽고, 안전하게 보호하는 방법, Global Pass!👇\nGlobal Pass 인증을 완료하시면 Global Pass 인증 카드를 발급해 드리며, 채널 소유권 인증, 수익화, 저작권 보호 등 다양한 혜택을 받으실 수 있습니다.🎉",
    "modal_subtitle_tips": "Global PASS 인증 전, 체험판을 통해 adobaro를 먼저 경험해보실 수 있습니다.",
    "modal_plus_title": "Global Plus PASS 인증이 필요합니다.",
    "modal_plus_subtitle": "Global Plus PASS를 인증하시면, 아래의 기능을 사용하실 수 있습니다.",
    "modal_item1": "플랫폼 활동으로 인한 수익발생의 시작!",
    "modal_item2": "더 많은 플랫폼 개통",
    "modal_item3": "데이터 맞춤형 분석시스템 제공",
    "modal_item4": "그 밖의 다양한 기능이 제공됩니다.",
    "modal_btn_next": "다음에 하겠습니다.",
    "modal_btn_go": "PASS 인증하러 가기",
    "modal_btn_trial": "체험하러 가기",
    "modal_certify_title": "플랫폼 수익화의 자격요건 들어보셨나요?",
    "modal_certify_column_1": "자격요건",
    "modal_certify_column_2": "Y사",
    "modal_certify_column_3": "adobaro",
    "modal_certify_require_1": "구독자",
    "modal_certify_require_2": "시청시간",
    "modal_certify_require_3": "조회수",
    "modal_certify_company_1": "1,000명",
    "modal_certify_company_2": "4,000시간",
    "modal_certify_company_3": "1,000만",
    "modal_certify_ro_1": "0",
    "modal_certify_ro_2": "0",
    "modal_certify_ro_3": "0",
    "modal_certify_explication_1": "Y사 평균 수익화까지의 소요시간 대략 7개월?\n아도바로는 Global PASS 인증과 동시에 수익화가 개통됩니다.",
    "modal_certify_explication_2": "그 뿐 일까요?\n개통 가능 플랫폼 확장\n업로드 주 2회로 업그레이드\n파일 스토리지 용량 증가 등등등!",
    "modal_certify_explication_3": "Global PASS 인증 혜택을 지금 바로 시작해보세요",
    "modal_certify_btn": "PASS 인증 후 수익 시작!",
    "auth_center": "Global PASS",
    "auth_center_subtitle": "인증 정보 관리",
    "auth_global": "Global PASS",
    "auth_creator": "Global Plus PASS",
    "auth_history": "사용 이력",
    "auth_global_subtitle": "플랫폼 수익 개통, 채널 안전성 향상",
    "auth_creator_subtitle": "조건 달성 후 개통 가능",
    "auth_history_subtitle": "플랫폼별 PASS 사용 히스토리",
    "auth_global_title": "Global PASS",
    "auth_creator_title": "Global Plus PASS",
    "auth_history_title": "PASS 사용내역",
    "creator_auth_tip": "Global PASS를 먼저 진행해 주세요.",
    "pass_title": "인증완료",
    "passport_title": "여권 정보",
    "passport_tip": "플랫폼별 정산을 받기 위해서는 Global PASS를 인증해야 합니다. 아래와 같이 인증에 필요한 서류를 제출해 주세요.",
    "platform_title": "플랫폼 정보",
    "platform_name": "플랫폼 명",
    "platform_url": "플랫폼 링크",
    "passport_dashboard_title": "서명된 여권 스캔본",
    "passport_dashboard_tip": "서명된 상태인지 확인 후 파일을 업로드해 주세요.",
    "passport_handhold_title": "서명된 여권과 본인 셀카",
    "passport_handhold_tip": "서명된 여권이 잘 보이도록 촬영해야 합니다.",
    "platform_dashboard_title": "스튜디오 대쉬보드 스크린샷",
    "platform_handhold_title": "대쉬보드와 본인 인증 셀카",
    "btn_submit_picture": "인증을 위한 정보 제공에 동의합니다.",
    "protocol_tip": "{0} 를 충분히 숙지했고, 이에 동의합니다.",
    "protocol_platform": "아도바로 위임 동의서",
    "passport_question": "왜 여권이 필요한가요?",
    "passport_answer": "해외 여행을 가실 때 여권을 가지고 가듯이 해외 플랫폼 운영 시에도 본인 확인을 위한 여권이 사용됩니다.\n중국 플랫폼의 가입을 위해서는 실명인증이 필요하며, 아도바로의 Global PASS 인증 시스템을 통해 귀하의 개인 정보가 보호됩니다.\nGlobal PASS 인증을 통하면 별도의 심사 없이 각각의 플랫폼의 업로드 권한이 주어지며, 플랫폼에서의 활동으로 인한 수익도 발생됩니다.",
    "passport_income_comparison": "Y사의 수익화 자격 요건과 아도바로 비교",
    "passport_pass_success": "아도바로 Global PASS 인증 시 수익화 자격 획득",
    "pass_no": "인증 번호",
    "pass_duration": "인증 기간",
    "pass_explain": "{0}를 위해 제출한 여권 정보는 개인정보 보호를 위해 표시되지 않습니다.",
    "pass_in_review": "{0}이 신청되었습니다. 심사일은 업무일 기준 3~5일 정도 소요되며, 심사 기간 중 본인 인증을 위한 메일이 발송됩니다.",
    "pass_in_fail": "{0} 심사가 승인되지 않았습니다. 미승인 사유: {1}",
    "pass_expire_warning": "{0}이 {1}에 만료됩니다. 원활한 이용을 위해 재신청을 진행해 주세요.",
    "pass_expire_already": "{0}가 만료되었습니다. 원활한 이용을 위해 재신청을 진행해 주세요.",
    "pass_submit_success_tip": "{0}가 안전하게 제출되었습니다. 심사일은 업무일 기준 3~5일 정도 소요되며, 심사를 기다리시는 동안에도 플랫폼 개통 신청은 가능합니다.",
    "pass_submit_fail_tip": "{0} 제출이 실패했습니다.",
    "auth_attention": "인증 시, 주의 사항",
    "global_attention_question_1": "1. 왜 여권이 필요한가요?",
    "global_attention_answer_1": "여권이 필요한 것의 대답",
    "global_attention_question_2": "2. 두번째 질문",
    "global_attention_answer_2": "두번쨰 질문에 대한 답",
    "global_attention_question_3": "3. 세번째 질문",
    "global_attention_answer_3": "세번째 질문에 대한 답",
    "global_attention_question_4": "4. 네번째 질문",
    "global_attention_answer_4": "네번째 질문에 대한 답",
    "global_attention_pass_question_1": "1. 인증 후, Global 인증을 취소하려면 어떻게 해야 하나요?",
    "global_attention_pass_answer_1": "답변",
    "global_attention_pass_question_2": "2. 두번째 질문",
    "global_attention_pass_answer_2": "답변",
    "creator_attention_question_1": "1. 왜 플랫폼 대쉬보드가 필요한가요?",
    "creator_attention_answer_1": "답변",
    "creator_attention_question_2": "2. 두번째 질문",
    "creator_attention_answer_2": "두번쨰 질문에 대한 답",
    "creator_attention_question_3": "3. 세번째 질문",
    "creator_attention_answer_3": "세번째 질문에 대한 답",
    "creator_attention_question_4": "4. 네번째 질문",
    "creator_attention_answer_4": "네번째 질문에 대한 답",
    "creator_attention_pass_question_1": "1. 인증 후, Global Plus PASS인증을 취소하려면 어떻게 해야 하나요?",
    "creator_attention_pass_answer_1": "답변",
    "creator_attention_pass_question_2": "2. 질문",
    "creator_attention_pass_answer_2": "답변",
    "attention_know_more": "더 알아보기",
    "cancel_auth": "PASS 인증 취소",
    "cancel_auth_title": "PASS 인증을 정말 취소하시겠습니까?",
    "cancel_auth_subtitle": "PASS 인증을 취소하게 되면, 모든 운영 채널의 수익이 정지되며, 개통된 채널은 모두 탈퇴처리가 됩니다. 그대로 진행하시겠습니까?",
    "cancel_auth_success": "PASS 인증 취소 성공",
    "cancel_auth_fail": "PASS 인증 취소 실패",
    "attention_approve": "위 답변에 만족하시나요?",
    "history_country": "국가",
    "history_platform": "플랫폼",
    "history_auth_type": "PASS 인증",
    "history_use_todo": "용도",
    "history_timestamp": "시간",
    "use_type_handling": "불펌처리",
    "use_type_open_income": "플랫폼 수익",
    "use_type_open_platform": "채널 개설",
    "upload_global_pass": "Global Pass 인증하기",
    "upload_cancel": "취소",
    "upload_confirm": "확인",
    "global_pass_failed": "여권 인증 실패",
    "global_pass_failed_des": "플랫폼 피드백에 따라 인증에 실패했습니다. 실패 사유: {0}. 정보를 검토한 후 다시 업로드해 주세요.",
    "upload_btn": "정보 업로드",
    "account_create_success": "Global Pass 사용 동의",
    "allow_global_pass": "플랫폼 채널 개설 및 인증 절차를 위해 Global Pass 정보 사용에 동의해 주세요.",
    "allow_and_accredit": "동의",
    "upload_global_pass_subtitle": "Global Pass 인증을 위해 본인 명의 여권을 제출해 주세요. ",
    "example_pic_tip": "예시",
    "back_trial_version": "닫기",
    "upload_pass_more": "귀하의 여권 정보가 이미 제출되었습니다.",
    "upload_pass_tips": "*제출된 자료는 Global Pass 인증 목적으로만 사용되며, 모든 개인 정보는 철저히 보호됩니다."
  },
  "upload": {
    "file_btn_status_removed": "업로드",
    "file_btn_status_uploading": "업로드 중",
    "file_btn_status_done": "파일 변경",
    "file_btn_status_error": "재업로드",
    "file_dragger_subtitle": "업로드 또는 파일을 여기로 끌어 놓으세요.",
    "file_dragger_tip": "지원파일: {0}",
    "file_status_uploading": "업로드 중:",
    "file_status_done": "완료",
    "file_status_error": "업로드 실패",
    "file_type_error": "지원하지 않는 파일 형식입니다.",
    "file_limit_size": "파일 크기는 {0} 를 넘어선 안 됩니다.",
    "file_limit_size_tip": "(최대 {0})",
    "file_limit_min_size": "(*{0} 이하)",
    "file_change_tip": "파일 변경",
    "file_upload_image_tip": "썸네일 업로드",
    "file_change_image_tip": "썸네일 변경",
    "file_name_already_exist": "파일저장공간 내 중복된 파일명이 있습니다. 파일명을 변경 후 다시 업로드 해 주세요.",
    "file_size_exceeds_capacity": "스토리지 용량이 가득 찼습니다.잔여 용량을 확인해 주세요.",
    "header_title": "업로드",
    "header_subtitle": "(✿◡‿◡) 콘텐츠를 업로드 하시면, 각 플랫폼에 최적화된 제목과 썸네일이 자동 생성되어 업로드됩니다. ✌️",
    "radio_quick": "간편링크 업로드",
    "radio_local": "파일 업로드",
    "label_video_link": "영상 링크",
    "video_link_holder": "유튜브/틱톡 영상 링크를 입력하세요. ",
    "video_link_invalid_msg": "찾을 수 없는 링크에요",
    "video_link_title_msg": "영상 타이틀을 불러올 수 없어요, 잠시 후에 다시 실행하세요. ",
    "video_link_title_holder": "링크확인이 완료되면 영상 및 타이틀이 자동 입력돼요.",
    "upload_file_holder": "파일을 업로드해 주세요.",
    "upload_file_complete_tip": "파일 업로드가 완료된 후 진행해 주세요.",
    "select_platform": "채널 선택",
    "select_platform_holder": "업로드할 채널을 선택해 주세요.",
    "open_more_platform": "채널 개설하기",
    "basic_info": "콘텐츠 정보",
    "basic_info_tip": "adoba.AI가 각각의 플랫폼에 최적화된 콘텐츠로 자동 변환되어 업로드됩니다.",
    "basic_content_title": "콘텐츠 제목",
    "basic_content_title_holder": "콘텐츠 제목을 입력해 주세요. 입력하신 내용은 플랫폼에 맞게 자동으로 최적화된 중국어로 번역되어 업로드됩니다.",
    "basic_content_cover": "썸네일",
    "basic_content_cover_holder": "썸네일을 업로드해 주세요.",
    "basic_content_cover_upload": "지원파일: {0}",
    "basic_content_cover_upload_tip": "⭐AI썸네일 기능을 통해 각 플랫폼에 최적화된 썸네일이 자동으로 생성되어 업로드됩니다.\r\n🚨AI썸네일 기능을 사용하기 위해서는 반드시 썸네일 이미지에 글자가 포함되어 있지 않는 클린본을 업로드해 주세요.",
    "basic_content_cover_change_tip": "썸네일 변경",
    "basic_content_intro": "콘텐츠 소개",
    "basic_content_intro_holder": "콘텐츠 소개 내용을 입력해 주세요. 입력하신 내용은 플랫폼에 맞게 자동으로 최적화된 중국어로 번역되어 업로드됩니다.",
    "btn_release": "AI 스마트 업로드",
    "release_content_success": "업로드 신청이 완료되었습니다.🎉\r\n업로드 진행결과는 알림 및 이메일을 통해 안내드립니다.\r\n💡불법, 성인물 등 각 플랫폼 규정에 따라 적합성 심사가 진행되며 그 결과에 따라 업로드 요청이 거절될 수 있습니다.",
    "release_content_fail": "업로드에 실패했습니다.",
    "release_count": "{0}회 남음",
    "platform_frozen": "잠김",
    "platform_unbound": "인증 필요",
    "publish_count_out": "가능한 업로드 횟수를 모두 사용 완료하여, 업로드가 불가합니다.",
    "release_upload_tips": "⭐AI 스마트 업로드 기능을 통해 콘텐츠와 각 플랫폼 주요 활성화 시간을 분석하여, 가장 최적의 시간에 맞춰 자동으로 업로드됩니다.\n🚨본인 확인 절차가 필요한 경우, 인증번호 확인절차가 필요할 수 있습니다.",
    "release_time_tips": "⚠️업로드 횟수는 현지 시간 기준 {0} (UTC 00:00)에 갱신됩니다."
  },
  "wallet": {
    "income_title": "수익",
    "income_overview": "수익 개요",
    "income_platform": "플랫폼별 수익",
    "income_member": "멤버별 수익",
    "income_total": "누적 수익",
    "income_total_tip": "누적 수익",
    "withdraw_total": "누적 출금액",
    "withdraw_total_tip": "누적 정산액",
    "revenue_balance": "계정 잔액",
    "revenue_balance_tip": "미정산 계정 잔액",
    "account_info": "계좌정보",
    "apply_withdraw": "출금신청",
    "settlement_detail": "정산내역",
    "detail_date": "출금일시",
    "detail_total": "수익 합계(VAT포함)",
    "detail_tax": "세금 공제",
    "detail_service_charge": "수수료",
    "detail_amount": "결제 금액",
    "detail_status": "결제 상태",
    "detail_action": "실행",
    "detail_status_ongoing": "처리중",
    "detail_status_success": "출금 성공",
    "detail_status_failure": "출금 실패",
    "withdrawable_detail_btn": "더 알아보기",
    "withdrawal_directions1": "⁕ 지갑 잔액이 [10,000]위안 초과 시 출금이 가능합니다. ",
    "withdrawal_directions2": "⁕ 출금 신청시 익월 말일 이전에 출금이 완료됩니다.(해외송금으로 입금시간 변동 가능)",
    "withdrawal_directions3": "⁕ 정산시 기본 수수료율 25%가 적용됩니다",
    "withdrawal_directions4": "⁕ 계정 잔액은 각 플랫폼에서 제공하는 수익금 데이터의 합이며, 정산 시기에 따라 변동될 수 있습니다. ",
    "withdrawal_directions5": "⁕ 수익 정산 시 수익 금액은 환율에 따라 변경될 수 있습니다.",
    "detail_modal_detail": "출금내역",
    "detail_modal_detail_table": "정산 명세서",
    "detail_modal_period": "정산 주기",
    "detail_modal_amount": "플랫폼 수익",
    "detail_modal_service_fee": "수수료",
    "detail_modal_tax": "세금 공제액",
    "detail_modal_settlement_amount": "세후 수익금",
    "detail_drawer_money_rate": "송금시점 환율",
    "detail_drawer_money_krw": "KRW 송금",
    "detail_drawer_tip": "KRW 출금시 개인소득세 3.3%가 공제된 금액이 입금됩니다",
    "account_paypal_tips": "Paypal은 안전하고 편리한 결제 서비스를 제공합니다.\n페이팔 계정(이메일)을 입력하고 계정 정보 설정을 완료하세요. \n페이팔 공식 홈페이지({0})에서 가입/계정 정보를 확인하세요",
    "account_paypal": "페이팔(Paypal)",
    "account_paypal_error": "페이팔 계정(이메일)을 입력하세요",
    "bank_not_set": "미설정",
    "account_info_change_header": "계좌정보 수정",
    "account_email_code": "메일 인증번호",
    "account_email_code_holder": "이메일 본인인증 코드를 입력하세요",
    "account_email_code_error": "유효기간이 만료되었거나 잘못된 인증코드입니다",
    "account_paypal_holder": "페이팔 계정을 입력하세요",
    "account_update_success": "계정 정보 업데이트 성공!",
    "account_update_fail": "계정 정보 업데이트 실패!",
    "monetary_unit_cny": "CNY",
    "monetary_unit_dollar": "USD",
    "monetary_unit_krw": "KRW",
    "income_month": "월 수익",
    "all_platform": "플랫폼 전체",
    "platform_income_detail": "플랫폼 수익 상세",
    "platform_income_title": "플랫폼",
    "platform_income_total": "플랫폼 수익(CNY)",
    "platform_income_daily": "데일리 수익",
    "platform_total": "월별 수익(CNY)",
    "member_top_10": "수익 Top10",
    "member_income_detail": "멤버 수익 내역",
    "member_name": "멤버명",
    "member_chart_total": "누적 수익(CNY)",
    "member_income_total": "멤버 수익(CNY)",
    "platform_drawer_tips_wait": "플랫폼별 데이터 수집시간이 상이하므로 시간이 지연될 수 있습니다. 잠시만 기다려주세요. ",
    "platform_drawer_tips_unit": "수익금 기준통화는 중국 위안화입니다",
    "platform_drawer_tips_account": "해당일의 수익은 MCN계좌에 입금되는 금액을 기준으로 합니다.",
    "platform_drawer_tips_date": "최근 36월 수익 데이터만 표시",
    "platform_drawer_title": "{0} 수익 상세",
    "platform_drawer_date": "일시",
    "platform_drawer_plan": "플랜",
    "platform_drawer_income": "플랫폼 수익",
    "platform_drawer_tax": "세금",
    "platform_drawer_service_fee": "수수료",
    "platform_drawer_total": "현황",
    "platform_drawer_settlement_amount": "정산금액",
    "confirm_header": "정보 확인",
    "detail_modal_bank_btn": "은행 정보를 입력하세요",
    "detail_modal_to_bank": "출금 신청을 위해 은행정보를 먼저 입력해주세요",
    "detail_modal_tips": "*세법에 따라 소득세 3.3%가 부과됩니다",
    "detail_modal_apply_btn": "출금확인",
    "platform_drawer_collecting": "데이터 수집이 진행중이에요",
    "platform_drawer_show_more": "펼쳐보기",
    "platform_drawer_show_close": "닫기",
    "member_income_detail_name": "계정명",
    "withdrawable_apply_disable": "현재 인출 조건이 충족되지 않았거나, 다시 확인 후 시도해 주세요.",
    "bank_verify_modal_save": "확인",
    "modify_phone_verify_head": "인증",
    "modify_phone_not_received": "인증번호를 못 받으셨나요?",
    "bank_verify_content": "계정 보안을 위해 본인 확인을 진행해 주세요. 메일로 인증번호가 전송되었으며, 인증번호를 입력하신 후 인증을 진행해 주세요.",
    "bank_verify_code_holder": "인증번호를 입력해 주세요.",
    "modal_success_title": "정산신청 성공",
    "modal_failure_title": "잠시 후 다시 시도해 주세요.",
    "modal_close_remind": "이 팝업창은 {0}초 후 자동으로 닫힙니다.",
    "account_setting": "설정/수정",
    "bank_update_fail": "은행 정보 업데이트에 실패했습니다.",
    "export_data": "데이터 내보내기",
    "detail_modal_tips_bank": "·계좌정보가 정확하지 않으면, 인출이 실패처리될 수 있습니다.",
    "detail_modal_tips_tax": "· 세법에 따라, 소득세 3.3%를 공제해야 합니다. ",
    "member_incomes_total": "합계",
    "overview_withdrawable": "정산 신청 가능 금액",
    "withdrawal_paypal_not_fount": "Paypal 계정이 연결되지 않았어요. 지금 연결할까요?",
    "detail_to_account_date": "예정일 {0}"
  },
  "setting": {
    "title": "설정",
    "subtitle": "adobaro 이용 관련 설정을 할 수 있습니다.",
    "enterprise_info": "기업정보",
    "account_security": "계정 보안",
    "notification": "알림",
    "protocol": "약관",
    "user_uid": "UID",
    "user_name": "기업명",
    "user_email": "이메일",
    "user_password": "비밀번호",
    "user_third_party_login": "서드파티 로그인",
    "user_not_set": "미설정",
    "user_set_password": "비밀번호 수정",
    "user_to_change": "변경",
    "user_bound": "연동됨",
    "user_to_bound": "연동하기",
    "user_bound_google": "구글 계정에 연동됨",
    "user_unbound_google": "구글 계정에 연동하기",
    "message_google_bound": "다른 아도바로 계정에 연동된 구글 계정입니다.",
    "user_phone_title_bind": "휴대폰 번호를 입력해 주세요.",
    "user_phone_subtitle_bind": "계정 보안을 위해 휴대폰 인증 후 진행이 가능합니다.",
    "user_phone_title_new": "신규 휴대폰 번호를 입력해 주세요.",
    "user_login_country_check": "국가를 선택해 주세요.",
    "user_modify_phone_verify_fail": "휴대폰 인증 실패",
    "user_support_email": "고객센터 이메일",
    "user_code_not_received_tip": "인증번호를 받지 못하셨다면 {0} 통해 처리해주시기 바랍니다.",
    "user_code_not_received": "인증번호를 못 받으셨나요?",
    "user_verify_header": "신분 인증",
    "user_modify_phone_header": "휴대폰 번호 변경",
    "user_modify_email_update_button": "이메일 변경",
    "user_modify_email_explanation": "계정 보안을 위해 이메일 인증 후 진행이 가능합니다.",
    "user_modify_email_old_holder": "현재 이메일을 입력해 주세요.",
    "user_modify_email_old_popover": "가입하신 이메일 주소가 기억나지 않으시면 {0} 통해 문의해주시기 바랍니다.",
    "user_modify_email_forgot": "이메일이 기억나지 않으시나요?",
    "user_modify_email_verify_fail": "이메일 인증 실패",
    "user_modify_new_email": "신규 이메일",
    "user_modify_email_new_holder": "신규 이메일을 입력해 주세요.",
    "user_modify_email_title": "이메일 변경",
    "user_modify_password_change_button": "비밀번호 변경",
    "user_modify_password_title": "비밀번호 변경",
    "user_modify_password_explanation": "계정 보안을 위해 이메일 인증번호 확인 후 진행이 가능합니다.",
    "user_modify_new_password": "신규 비밀번호",
    "user_modify_password_new_holder": "신규 비밀번호를 입력해 주세요.",
    "user_modify_password_confirm_holder": "신규 비밀번호를 재입력해 주세요.",
    "user_modify_password_head": "비밀번호 변경",
    "security_signin": "로그인 보안",
    "security_credit": "신용도",
    "security_signin_date": "로그인 시간",
    "security_signin_mode": "로그인 방식",
    "security_signin_third": "서드파티 로그인",
    "security_signin_google": "구글 로그인",
    "security_signin_account": "계정 로그인",
    "security_signin_ip": "IP주소",
    "security_signin_country": "국가",
    "security_signin_action": "집행",
    "delete_confirm_title": "정말 삭제하시겠습니까?",
    "credit_action_date": "집행시간",
    "credit_action_behavior": "행위",
    "credit_ score": "신용도",
    "protocol_user": "adobaro 이용약관",
    "protocol_privacy": "adobaro 개인정보 처리방침",
    "protocol_marketing": "마케팅 정보 수신 동의서",
    "notification_history": "알림 히스토리",
    "notice_date": "일시",
    "notice_title": "알림 제목",
    "notice_detail": "알림 내용",
    "delete_all_btn": "전부 삭제",
    "notice_delete_all": "전부 삭제하시겠습니까?",
    "notice_delete_all_subtitle": "전부 삭제 후, 알림 히스토리에서 확인 가능합니다.",
    "notice_details": "상세 내용",
    "notice_read_all": "모두 읽음",
    "notice_read_all_confirm": "모두 읽음으로 처리하시겠습니까?",
    "cancellation_apply": "연동해제 신청",
    "cancellation_record": "연동해제 기록",
    "cancellation_apply_initiate": "연동해제 ",
    "cancellation_apply_time": "신청 기간",
    "cancellation_account_name": "계정 이름",
    "cancellation_apply_side": "연동해제 ",
    "cancellation_status": "해제 상태",
    "cancellation_action": "실행",
    "cancellation_apply_side_creator": "크리에이터",
    "cancellation_apply_side_mcn": "기업",
    "cancellation_status_waiting": "연동해제 예정",
    "cancellation_status_reject": "거절됨",
    "cancellation_status_cancel": "취소됨",
    "cancellation_status_success": "연동해제 성공",
    "cancellation_action_reject": "거절합니다.",
    "cancellation_action_agree": "동의합니다.",
    "cancellation_action_cancel": "취소",
    "cancellation_action_resend": "다시 보내기",
    "cancellation_reject_title": "연동해제 신청을 거부하시겠습니까?",
    "cancellation_agree_title": "연동해제 신청에 동의하십니까?",
    "cancellation_cancel_title": "연동해제 신청을 취소하시겠습니까?",
    "cancellation_resend_title": "연동해제 신청을 다시 보내시겠습니까?",
    "cancellation_member": "멤버목록",
    "cancellation_member_placeholder": "멤버의 UID를 입력해 주세요.",
    "cancellation_member_btn": "연동해제",
    "cancellation_record_start_time": "계약기간",
    "cancellation_record_end_time": "연동해제 시간",
    "cancellation_record_income": "수익금",
    "cancellation_record_check_title": "사용자를 찾을 수 없습니다."
  },
  "userAgreement": {
    "title": "Adobaro 사용자 사용계약",
    "updated": "갱신시간：2023년【10】월【31】일",
    "effective": "효력발생 : 2023년【10】월【31】일",
    "introduction": "【머릿말】",
    "introduction_1": "《adobaro사용자사용계약》(이하'본계약')은 귀하와 adobaro 간 adobaro 의 각종 서비스사용에 관한 법적 계약서입니다. 'adobaro'란, adobaro 운영사인 adoba cultural media (Sichuan) Co., LTD. (安哆巴文化传媒（四川）有限公司) 및 관계사(이하 '회사')를 말합니다.",
    "introduction_2": "본 계약서에서 귀하의 권익(가능성)과 중대한 관계성이 있는 조항(adobaro 면책조항, 귀하의 권리 제한 조항, 분쟁의 해결 및 사법관할권 조항 등 포함)은 adobaro 에서 폰트를 강조하는 방식으로 특별히 강조하고 있사오니 주의해서 확인하시기 바랍니다. adobaro 서비스 사용 전 반드시 숙독하고 충분히 이해한 뒤에 본 계약의 전체 조항을 수용해야 합니다.",
    "introduction_3": "<adobaro 개인정보 정책>, <도움말 센터> 및 adobaro 에서 발표하는 기타 계약 규범, 성명, 공지, 정책 등(이하 '기타 adobaro 규칙'으로 통칭)은 본 계약과 분리될 수 없는 구성 요소로서 귀하가 adobaro 서비스 이용 시에도 반드시 준수해야 합니다.",
    "introduction_3_bold": "본 계약을 수용하고 동의함은 기타 adobaro 규칙도 함께 수용하고 동의하는 것으로 간주합니다. 또한 기타 adobaro 규칙을 수용하거나 동의함 역시 귀하가 본 계약을 수용하고 동의하는 것으로 간주합니다.",
    "introduction_4": "귀하는 온전한 민사적 행위능력 및 권리능력을 갖추고 본 계약에 동의하고 준수할 수 있으며, 본 계약에 규정된 모든 사항에 대해 독립적으로 법적 책임을 부담함을 확인하시기 바랍니다. 만일 귀하가 상기 행위에 적절한 민사적 행위능력을 갖추지 못한 경우, 반드시 법적 보호자의 동의를 얻어야 합니다. 귀하가 미성년인 경우 법적 보호자를 동반하여 본 계약 동의 여부를 읽고 판단해야 합니다. 미성년자 조항에 유의하시기 바랍니다. 미성년자가 본 계약에 정해진 권리 및 의무를 이행할 경우, 이는 법적 보호자의 승인을 이미 얻은 것으로 간주합니다.",
    "content_1": "서비스 설명",
    "content_1_1": "adobaro 는 다음을 포함하되 이에 국한하지 않는 서비스를 제공합니다:",
    "content_1_1_1": "adobaro 웹사이트: www.adobaro.com(및 기타 adobaro 가 운영하는 모든 웹사이트 또는 위탁 계약 사이트 또는 소프트웨어 관련 웹사이트 또는 소프트웨어);",
    "content_1_1_2": "adobaro 가 직접 운영하거나 제3자와 협력 운영하는 PC, 태블릿, 모바일, TV, 셋톱박스, 휴대장치, 차량용 태블릿 장치, 스마트 오디오 장치 등을 포함하되 이에 국한하지 않는 모든 고객간 단말 제품;",
    "content_1_1_3": "adobaro 사용자 소재 관리, 콘텐츠 관리, 플랫폼 관리;",
    "content_1_1_4": "adobaro 가 직접 소유하거나 운영하는 서버, 웹사이트 저장공간;",
    "content_1_1_5": "adobaro 가 귀하에게 제공하는 각종 가치 증진 서비스;",
    "content_1_1_6": "adobaro 가 제공하는 기타 기술 및/또는 서비스, 구체적으로 adobaro 가 실제 제공하는 내용을 기준으로 하며, adobaro 는 자체적으로 추가, 중단 또는 기타 조정을 진행할 수 있습니다.",
    "content_1_2": "adobaro 에서 제공하는 서비스는 상기 1.1.1 및 1.1.2 에 기술한 플랫폼(이하 'adobaro 관련 플랫폼') 내에서만 사용하도록 제한하며, 악의적 해킹이나 불법적 수단으로 adobaro 에서 제공하는 서비스 및 adobaro 관련 플랫폼을 분리하는 모든 행위는 본 계약에 약정된 adobaro 제공 서비스에 포함되지 않습니다. 이로 인해 발생하는 모든 결과는 행위자의 책임이며 adobaro 는 적법하게 행위자의 책임을 추궁할 법적 권리를 갖습니다. adobaro 가 제공하는 서비스는 사용자의 비상업적 사용만을 목적으로 하며, 사용자는 adobaro 의 사전 서명 동의 없이 상업적 목적으로 adobaro 에서 제공하는 서비스를 사용할 수 없습니다.",
    "content_1_3_bold": "adobaro 공식 배포는 고객 단말 등록, 로그인, 다운로드(iOs, Android 등 스마트 플랫폼을 포함하되 이에 국한하지 않음), adobaro 서비스 이용의 유일한 합법적 방식으로, 사용자가 그 어떠한 기타 경로, 채널, 방법으로 취득한 adobaro 서비스(계정, 고객 단말 다운로드 등을 포함하되 이에 국한하지 않음)는 일괄 불법 취득으로 간주됩니다. adobaro 는 해당 서비스의 효력을 인정하지 않으며, 발견 즉시 삭제, 초기화, 차단 등 조치를 취할 것이므로 이로 인해 발생하는 모든 불이익은 고객이 스스로 부담해야 합니다.",
    "content_1_3": "사용자는 자신의 계정과 비밀번호를 적절하게 보관하고 비밀번호 보안을 강화하며 계정 유출이나 탈취를 방지해야 합니다. 사용자 계정 유출 또는 탈취로 인해 발생하는 모든 손실에 대해 adobaro 는 배상의 책임을 부담하지 않습니다.",
    "content_1_4": "adobaro 는 전항에 대해 사용자에게 (팝업 공지, 웹사이트 홈페이지 공지, 시스템 메시지 등을 포함하되 이에 국한하지 않음)을 통해 adobaro 서비스 관련 소프트웨어 수정, 변경, 업그레이드를 고지합니다.",
    "content_1_4_bold": "만일 사용자가 adobaro 관련 소프트웨어 수정, 변경, 업그레이드에 동의하지 않을 경우에는 사용을 직접 거절, 중지, 취소할 수 있습니다. 그렇지 아니한 사용자는 adobaro 관련 소프트웨어 수정, 변경, 업그레이드에 동의하였으므로 여전히 본 계약 조항의 제약을 받는 것으로 간주합니다.",
    "content_1_5": "본 계약에서 지칭하는 '사용자' 는 adobaro 계정을 취득한 사용자(이하 '등록 사용자') 및 adobaro 계정을 아직 취득하지 않은 사용자(이하 '미등록 사용자')를 포함합니다. adobaro 에 등록되지 않은 제품/또는 서비스의 사용자는 adobaro 제품을 다운로드 및/또는 adobaro 서비스 이용 시 자동으로 adobaro 의 '미등록 사용자'가 됩니다.",
    "content_1_6": "주의: adobaro 제품 및 서비스가 다양하므로 제공되는 제품과 서비스 콘텐츠가 상이할 수 있으며, 본 계약은 adobaro 통합 표준 사용자 서비스 계약에 해당합니다. adobaro 는 adobaro 의 특정 제품/서비스에 대해 사용자 서비스 계약을 별도 제정하여 구체적으로 귀하와 adobaro 간의 서비스 콘텐츠, 서비스 규칙 등 내용을 약정하므로, 당사는 귀하가 특별 고객 서비스 계약의 전체 내용을 반드시 숙지한 뒤 해당 특정 제품/서비스를 이용할 것을 권장합니다.",
    "content_2": "계정 등록",
    "content_2_1": "adobaro 가 제공하는 네트워크 저장 공간 내 방송, 텍스트, 미술 촬영 콘텐츠 업로드 및 배포 등 일부 adobaro 서비스는 등록 고객에게만 제공되므로, 귀하는 본 계약과 기타 adobaro 규칙에 규정된 프로세스에 따라 고객 등록을 진행하고 등록 정보의 진실성, 정확성, 완전성을 확인해야 하며, 등록 정보 변경 시 즉시 변경해야 합니다.",
    "content_2_2": "귀하는 adobaro 에 등록한 계정(이하 '계정')으로 진행한 모든 행위에 대해 책임을 부담하고 중화인민공화국(이하 '중국' 또는 '국가') 법률, 사회 도덕 및 미풍양속과 정보 진실성 등 원칙을 준수해야 합니다. 허위 정보를 배포하거나 타인의 성명, 명칭, 초상, 호칭을 도용하거나 기타 충분히 혼동을 일으킬 수 있는 방식으로 계정을 설정할 수 없으며, adobaro 를 포함한 모든 주체의 합법적 권익을 침해할 수 없습니다.",
    "content_2_3": "귀하는 계정 및 계정을 통해 adobaro 가 제공하는 모의 제품과 서비스에 대한 사용권만을 가지며, 계정 및 해당 모의 제품과 서비스의 소유권은 adobaro 가 가집니다(법적으로 별도 규정된 경우 제외). adobaro 의 서면 동의 없이는 그 어떠한 방식으로도 계정 사용권을 처분(증여, 임대, 양도, 판매, 저당, 계승, 타인 사용 허가를 포함하되 이에 국한하지 않음)할 수 없습니다.",
    "content_2_3_bold": "adobaro 가 사용자가 계정의 최초 등록자가 아님을 발견하였거나 합리적으로 판단할 수 있는 경우, adobaro 는 귀하에게 통보 없이 해당 등록 계정에 제공되는 서비스를 중단하거나 종료할 수 있으며, 해당 계정을 삭제할 수 있습니다. 이로 인해 발생하는 콘텐츠 또는 모의 자산의 손실 등은 귀하 본인 또는 계정 사용자가 부담해야 합니다.",
    "content_2_4": "사용자는 계정 정보, 계정 비밀번호 및 기타 계정 관련 정보, 자료를 적절히 보관해야 합니다. 귀하의 사유로 계정 정보, 자료, 데이터 변경, 멸실 또는 재산 손실 등이 발생한 경우, 반드시 즉시 adobaro 에 이를 통보하고 법적 책임을 부담해야 합니다.",
    "content_2_5": "제3자 웹사이트 또는 서비스를 통해 adobaro 계정을 등록한 경우, adobaro 의 별도 설명이 없는 한 adobaro 가 이들 웹사이트 또는 서비스를 운영하지 않으므로, 제3자 웹사이트의 안전성과 사용성을 사용자 스스로 판단하여 관련 위험과 책임을 부담해야 합니다.",
    "content_2_6": "귀하는 다음에 동의하고 서약합니다:",
    "content_2_6_1": "귀하의 계정 또는 비밀번호가 허가 없이 사용되거나, 보안 문제가 발생한 경우 반드시 유효한 방법으로 adobaro 에 이를 통보합니다;",
    "content_2_6_2": "adobaro 에 로그인하거나 관련 서비스를 사용한 뒤에는 관련 계정 등을 안전하게 로그아웃합니다;",
    "content_2_6_3": "adobaro 가 전자우편, 고객 단말, 웹페이지 또는 기타 합법적 방식으로 귀하에게 전송하는 정보 및 기타 관련 정보를 수신하는데 동의합니다.",
    "content_2_7": "귀하는 다음의 행위에 종사하지 않을 것을 동의하고 서약합니다:",
    "content_2_7_1": "타인의 정보를 도용하여 adobaro 계정 로그인;",
    "content_2_7_2": "타인의 합법적 허가 없이 타인의 명의로 adobaro 계정 등록;",
    "content_2_7_3": "동일한 신원 정보로 다수의 adobaro 계정 등록(adobaro 심사를 거쳐 인정된 다수 adobaro 계정의 실제 지배자가 동일인인 경우 포함);",
    "content_2_7_4": "타인의 adobaro 계정, 회원 아이디 등을 절도, 도용;",
    "content_2_7_5": "모욕성, 비방성, 음란성, 정치성 등 중화인민공화국 법률, 도덕, 미풍양속을 저해하는 어휘로 adobaro 계정 등록;",
    "content_2_7_6": "불법적으로 adobaro 관련 서비스 리소스를 점유하기 위해 정당하거나 정당하지 아니한 수단을 통하여 악의적으로 웹사이트 허점을 이용;",
    "content_2_7_7": "타인의 합법적 권일 및 기타 콘텐츠를 침해하는 행위.",
    "content_2_8": "귀하는 상기 조항을 위반한 사용자에게 adobaro 가 등록 금지 및/또는 계정 차단 등 조치를 취할 수 있음을 알고 이에 동의합니다.",
    "content_2_9": "adobaro 에서 제공하는 서비스는 adobaro 가 중국의 제3자 네트워크 운영 플랫폼(이하: 중국 플랫폼 또는 플랫폼)을 포함하므로, 귀하는 다음 내용에 유의할 것을 권장합니다:",
    "content_2_9_1": "등록 계정과 운영 플랫폼 계정의 일관성을 보장하기 위해, 귀하는 반드시 본인의 휴대전화번호를 플랫폼 개통 계정으로 해야 합니다;",
    "content_2_9_2": "adobaro 는 adobaro 에 게시된 플랫폼에 대해서만 개통 신청을 지원합니다. adobaro 에 게시되지 않은 플랫폼은 adobaro 에서 현재 지원하지 않거나 운영 관련 책임을 부담하지 않음을 의미합니다;",
    "content_2_9_3": "adobaro 에 개통된 플랫폼은 귀하 본인이 직접 신청 양식에 따라 직접 신청을 진행합니다. adobaro 는 모든 신청을 귀하 본인이 진행한 것으로 간주합니다. 귀하가 신청하면 adobaro 가 귀하의 휴대전화번호로 플랫폼 신청 등록을 진행하는 데 동의한 것으로 간주합니다;",
    "content_2_9_4": "중국 플랫폼은 비정기적, 무작위적으로 해당 운영 규칙을 변경할 수 있으므로 adobaro 는 변경 정보를 수신한 즉시 변경된 규칙을 공지 형태로 귀하에게 고지합니다. 따라서 귀하는 adobaro 공지에 유의하여 플랫폼 운영 정책 변경으로 인한 손실을 방지해야 합니다;",
    "content_2_9_5": "중국 플랫폼 개통 전, 귀하의 휴대전화번호를 장기적으로 사용할 수 있는지 확인해야 합니다. 만약 귀하의 휴대전화번호가 adobaro 에서 플랫폼을 성공적으로 개통하였으나 휴대전화번호를 사용하지 않거나 복구할 수 없어 플랫폼에 로그인할 수 없는 경우, 또는 장기 미사용 등 사유로 해당 플랫폼의 계정이 폐기된 경우 발생하는 손실은 귀하 본인이 부담합니다(플랫폼 계정 손실, 모의 자산 손실 포함); 귀하가 신규 휴대전화번호로 플랫폼 개통을 신청할 경우, 해당 휴대전화번호에 대응하는 계정은 새로운 신규 계정이 되어 이전 휴대전화번호와 상충 또는 교차되지 않습니다.",
    "content_2_10": "귀하는 계정을 사용해 adobaro 에서 제공하는 adobaro 시스템 산하 모든 제품에 등록함을 인지하고 이해하였습니다.",
    "content_2_11": "귀하는 adobaro 가 법적으로 허가하는 범위 내에서 상황에 따라 계정 사용권을 회수할 수 있으며, 이 때 사용자에게 별도로 통보하여 동의를 구하지 아",
    "content_2_12": "귀하가 본 웹사이트의 계정 및 해당 계정으로 배포하는 모든 콘텐츠에 대해, adobaro 는 귀하 계정과 자동 동기화하여 회사 및/또는 관계사 및/또는 회사 운영을 제어하는 기타 소프트웨어 및 웹사이트, 회사 및/또는 관계사 및/또는 지주사가 적절한 형태로 기타 소프트웨어 및 웹사이트에 게재할 수 있음을 수용하고 이에 동의합니다. adobaro 에서 귀하가 콘텐츠를 배포, 수정, 삭제하는 경우, 상기 일련의 기타 소프트웨어 및 웹사이트도 함께 동기화합니다. 이미 등록한 계정이나 제3자 소프트웨어용 계정으로 회사 및/또는 관계사 및/또는 지주사가 운영하는 기타 소프트웨어 제품 및 웹사이트(있을 경우)에 등록할 경우, 반드시 해당 소프트웨어 및 웹사이트 자체의 <사용자 계약> 및 기타 계약조항 규정을 준수해야 합니다. 논쟁을 피하기 위해, 귀하는 본 웹사이트의 계정 및 계정에서 배포한 콘텐츠의 전체 또는 일부가 회사에서 제공하는 콘텐츠 서비스, 플랫폼 신청 및 개통을 진행했던 고객 단말 애플리케이션 및 웹사이트에 동기화됨을 확인하고 이에 동의합니다. 해당 라이선스는 해당 라이선스 기간 내 무료이며, 철회 불가하고, 독점적이며, 지역적 제약을 받지 않습니다.",
    "content_2_13": "계정 리소스를 충분히 사용하기 위해 adobaro 는 각 플랫폼별 규칙에 따라 운영함을 이해하고 이에 동의합니다.",
    "content_3": "adobaro 콘텐츠",
    "content_3_1": "adobaro 콘텐츠란, adobaro 가 제공하는 서비스입니다. 업로드, 게시 또는 기타 수단을 통해 전송 또는 전파하는 모든 정보, 자료, 텍스트, 소프트웨어, 음악, 오디오, 사진, 이미지, 영상, 메시지, 링크 또는 기타 자료이며 공개 여부를 구분하지 않습니다(이하 '콘텐츠'). 콘텐츠 제공자, 업로더는 자신이 제공, 업로드하는 콘텐츠에 대한 모든 책임을 부담하고",
    "content_3_1_bold": "adobaro 에게 손실을 끼치는 경우 adobaro 에 대한 배상의 책임도 함께 부담합니다. 제3자가 사용자가 업로드한 콘텐츠에 대하여 adobaro 에게 권리를 주장할 경우, adobaro 는 콘텐츠 제공자, 업로더에게 사전 통보 없이 바로 삭제, 차단, 숨김 처리 등 필요 조치를 취할 수 있습니다.",
    "content_3_2": "adobaro 에서 업로드 또는 배포하는 콘텐츠에 대해 귀하는 합법적 저작권 또는 상응하는 라이선스를 보유해야 하며, adobaro 는 전술한 콘텐츠를 표시, 배포, 홍보할 수 있습니다.",
    "content_3_3": "adobaro 는 추가적으로 귀하에게 글로벌 표시 및 홍보 서비스를 제공하며, 귀하는 adobaro 가 귀하의 계정으로 배포되는 모든 콘텐츠를 adobaro 가 직접 운영하거나 제3자와 협력하여 운영하는 제품에 게시할 수 있음에 동의합니다. 이는 PC, 태블릿, 모바일, TV, 셋톱박스, 휴대장치, 차량용 태블릿 장치,스마트 오디오 장치 등 모든 고객 단말 소프트웨어 및/또는 웹사이트를 포함하되 이에 국한하지 않습니다. 그 밖에, 귀하는 adobaro 가 콘텐츠를 게시할 때 직접 또는 제3자에 위탁하여 필요한 처리(번역, 편집, 수정 등을 포함하되 이에 국한하지 않음)를 진행함에 동의합니다. 귀하는 adobaro 가 자체적으로 또는 제3자에 위탁하여 상기 콘텐츠, adobaro 제품 및 관련 서비스, adobaro 및/또는 adobaro 브랜드에 관한 모든 홍보, 마케팅 및/또는 R&D 를 진행함에 적절한 방식으로 상기 콘텐츠(전부 또는 일부)를 개발하고 사용할 수 있음을 확인하고 이에 동의합니다. 분쟁을 피하기 위해, 귀하는 상기 콘텐츠에 귀하가 소유하거나 사용을 허가 받아 콘텐츠에 삽입한 개인의 초상, 이미지, 상표, 서비스 로고, 브랜드, 명칭, 마크 등이 포함된다는 것을 인지하고 이에 동의합니다.",
    "content_3_4": "adobaro 가 사전에 예견할 수 없고 합리적으로 제어할 수 없는 adobaro 서비스를 통해 업로드 되는 콘텐츠로 인해 귀하가 adobaro 서비스 이용 시 불쾌하거나 불편하거나 혐오감을 느끼게 하는 일부 콘텐츠를 접할 수 있음을 인지하였으므로, 귀하는 이로 인해 adobaro 에 제기하는 모든 청구권을 포기하는데 동의합니다. 단, adobaro는 전술한 콘텐츠의 전송을 중지하고 이에 상응하는 조치를 취할 수 있으며, 이는 귀하가 지속적으로 adobaro 의 일부 또는 전체 서비스를 사용할 수 없도록 서비스 제공을 중단하여 관련 기록을 관계 기관에 보고하는 것을 포함하되 이에 국한하지 않습니다.",
    "content_4": "사용 규칙",
    "content_4_1": "사용자는 adobaro 서비스 이용 시 반드시 다음 법규를 준수합니다:",
    "content_4_1_1": "<중화인민공화국국가기밀보장법(中华人民共和国保守国家秘密法)>",
    "content_4_1_2": "<중화인민공화국저작권법(中华人民共和国著作权法)>；",
    "content_4_1_3": "<중화인민공화국컴퓨터시스템안전보호조례(中华人民共和国计算机信息系统安全保护条例)>；",
    "content_4_1_4": "<컴퓨터소프트웨어보호조례(计算机软件保护条例)>；",
    "content_4_1_5": "<네트워크정보콘텐츠생태계관리규정(网络信息内容生态治理规定)>",
    "content_4_1_6": "<정보네트워크전파권보호조례(信息网络传播权保护条例)>",
    "content_4_1_7": "<중화인민공화국네트워크안전법(中华人民共和国网络安全法)>；",
    "content_4_1_8": "기타 법률, 법규, 정관, 조례 등 법적 구속력을 가진 규범.",
    "content_4_2": "사용자는 다음 행위에 종사할 수 없습니다:",
    "content_4_2_1": "헌법에 명시된 기본 원칙에 반하거나 국가 안보를 위협하거나 국가 기밀을 누설하거나 국가 정권을 전복하거나 국가 통일을 파괴하거나 민족의 단결을 저해하거나 국가 명예와 이익을 손상하거나 민족 혐오, 차별을 조장하거나 민족의 단결을 저해하거나 국가 종교 정책을 파괴하거나 사이비 종교나 미신을 조장하거나 음란물, 도박, 폭력, 강력범죄, 테러 또는 범죄 교사에 관련되거나, 타인을 모욕 또는 비방하거나 타인의 합법적인 권익을 침해하는 등 법적, 행정적으로 금지된 콘텐츠 또는 타인에게 반감을 사는 정보, 자료, 텍스트, 소프트웨어, 음악, 사진, 이미지, 메시지 또는 기타 자료를 제작, 업로드, 복제, 전송, 전파하는 행위;",
    "content_4_2_2": "미성년자에게 악영향을 미치는 모든 행위;",
    "content_4_2_3": "개인이나 기관을 사칭하거나 허위나 거짓된 방식으로 개인이나 기관을 오도하는 행위;",
    "content_4_2_4": "제목을 위조하거나 다른 방법으로 자료를 식별하여 해당 콘텐츠가 adobaro 에서 전송한 것으로 오해하도록 하는 행위;",
    "content_4_2_5": "전송 권한이 없는 콘텐츠(예: 내부 자료, 기밀 자료)를 업로드, 게시, 전자우편 발송 또는 기타 방식으로 전송하는 행위;",
    "content_4_2_6": "타인의 특허, 상표, 저작권, 영업 기밀 등 지적재산권을 침해하는 콘텐츠를 업로드, 게시, 전자우편 발송 또는 기타 방식으로 전송하는 행위;",
    "content_4_2_7": "광고물, 판촉물, '스팸 메일' 등을 업로드, 게시, 전자우편 발송 또는 기타 방식으로 전송하는 행위;",
    "content_4_2_8": "타인을 스토킹하거나 다른 방법으로 괴롭히는 행위;",
    "content_4_2_9": "컴퓨터 소프트웨어, 하드웨어 또는 통신장비 성능을 방해, 파괴하거나 제한하는 바이러스 또는 기타 컴퓨터 코드, 파일, 프로그램 자료를 업로드, 게시, 전자우편 발송 또는 기타 방식으로 전송하는 행위;",
    "content_4_2_10": "adobaro 서비스 또는 adobaro 서비스와 연결된 서버와 네트워크를 방해하거나 파괴하는 행위 또는 본 계약을 준수하지 않는 행위;",
    "content_4_2_11": "adobaro 사전 서명 승인 없이 모든 방법(로봇 프로그램, 스파이더 프로그램, 크롤러 소프트웨어 등 모든 자동 프로그램, 스크립트, 소프트웨어를 포함하되 이에 국한하지 않음)과 사유로 자발적 또는 타인에게 협조하거나 위탁받아 플랫폼 서비스, 콘텐츠, 데이터를 취득하는 행위;",
    "content_4_2_12": "귀하는 업로드 콘텐츠가 광전총국(广电总局) 관련 규정을 위반하지 아니함을 보장해야 하며, 이는 <인터넷 영상 프로그램 서비스 관리 규정(互联网视听节目服务管理规定)>(광전총국 56호 령(广电总局56号令))등을 포함하되 이에 국한하지 않습니다. 업로드한 방송은 반드시 법률 및 행정 법규, 관계 부처 정관 규정에 부합해야 하며 다음의 내용을 담지 않아야 합니다:",
    "content_4_2_12_1": "헌법에 명시된 기본 원칙에 반하는 내용;",
    "content_4_2_12_2": "국가 통일, 주권, 영토 완전성을 해치는 내용;",
    "content_4_2_12_3": "국가 기밀을 누설하거나 국가 안보를 저해하거나 국가의 명예와 이익을 손상하는 내용;",
    "content_4_2_12_4": "민족 혐오, 민족 차별을 조장하고 민족의 단결을 저해하거나, 민족적 미풍양속과 습관을 손상하는 내용;",
    "content_4_2_12_5": "사이비 종료나 미신을 조장하는 내용;",
    "content_4_2_12_6": "사회 질서를 어지럽히고 사회 안정을 파괴하는 내용;",
    "content_4_2_12_7": "미성년자를 불법적 범죄, 음란물, 도박, 테러리즘으로 유인하는 내용;",
    "content_4_2_12_8": "타인을 모욕하고 비방하거나 국민의 사생활을 침해하는 등 타인의 합법적 권익을 참해하는 내용;",
    "content_4_2_12_9": "사회 공공질서를 해치고 민족의 우수한 문화와 전통을 손상하는 내용;",
    "content_4_2_12_10": "법률, 행정 법규 및 국가 규정에서 금지하는 기타 내용.",
    "content_4_2_13_bold": "딥러닝이나 증강현실 등 신기술을 사용해 허위 정보 등 법률에서 금지하는 정보를 제작, 배포, 전파할 수 없습니다. 딥러닝, 증강현실 등 신기술을 적용해 제작한 비현실적 영상정보를 배포, 전파할 경우, 반드시 눈에 띄는 방법으로 표시해야 하며 그렇지 않은 경우 adobaro 는 관련 콘텐츠와 계정에 대하여 로고를 추가하거나 제한하거나 차단 등 조치를 취할 수 있습니다.",
    "content_4_2_13": "귀하가 제작, 배포, 전파하는 콘텐츠는 반드시 <네트워크 영상 정보 서비스 관리 규정(网络音视频信息服务管理规定)> 등 법률 규정 및 adobaro 에서 발표한 창작 규정에 부합해야 하며,",
    "content_4_2_14": "사용자가 제공한 업로드 콘텐츠에 상기 정책 및 법률을 위반하는 정보 또는 콘텐츠가 포함되어 발생한 모든 불이익은 사용자가 직접 부담해야 하며, 이로 인해 adobaro 에 불리한 영향이 미친 경우 귀하는 책임을 지고 불이익을 해소해야 합니다. 또한 귀하는 이로 인해 발생한 모든 손실에 대한 배상 의무를 집니다.",
    "content_4_3": "사용자는 그 어떤 채널 또는 미디어(자체 미디어 등을 포함하되 이에 국한하지 않음)를 통해서도 'adobaro 와 협력', 'adobaro 공동 출품' 등 'adobaro' 브랜드 텍스트가 있는 영상을 배포할 수 없습니다. 만일 사용자가 협력 방송 홍보를 위해 영상 자체를 언급하더라도 adobaro 와의 관계를 언급하거나 임의로 adobaro 브랜드를 가지고 홍보할 수 없습니다. 'adobaro' 가 포함되는 모든 원고는 반드시 adobaro 해당 부서의 서면 동의가 필요하며, 이를 준수하지 아니하여 adobaro 에게 발생하는 모든 손실은 귀하가 배상의 책임을 집니다.",
    "content_4_4": "유료 plan 사용 규칙",
    "content_4_4_1": "사용자가 adobaro.com에서 유료 plan을 주문하는 것은 adobaro사용자 사용계약을 읽고 그 내용을 이해했으며, 사용자가 직접 선택한 유료 plan의 빌링에 맞춰 자동으로 매월 혹은 매년 비용이 청구될 수 있다는 것에 동의한 것을 의미합니다.",
    "content_4_4_2": "사용자는 유료 plan을 1개월 동안 무료로 사용할 수 있습니다. 무료 사용 기간이 끝나기 전에 직접 취소하지 않으면, 1개월 이후 자동으로 결제되며 매월 혹은 매년 갱신됩니다. 무료 기간은 adobaro내부 정책에 의해 변경되거나 삭제될 수 있습니다.",
    "content_4_4_3": "유료 plan이 갱신되는 기간 동안 사용자가 서비스를 이용하지 않더라도 이용 기간에 대해 연장하거나, 환불처리되지 않습니다.",
    "content_4_4_4": "유료 plan은 매월 혹은 매년 청구되며, 이는 사용자가 구독신청을 취소하지 않는 한 계속해서 비용이 청구된다는 것을 의미합니다. 구독신청의 취소에 대한 책임은 사용자에게 있습니다. 이메일을 통한 요청이나, 고객센터를 통해 구독신청을 취소하더라도 그것은 취소로 인정되지 않습니다. 사용자는 언제든지 plan 섹션에서 사용자의 구독신청을 취소할 수 있습니다.",
    "content_4_4_5": "사용자가 이미 결제한 기간이 끝나기 전에 유료 plan을 해지하더라도 유료 plan의 남은 기간은 서비스가 유지됩니다.",
    "content_4_4_6": "매월 혹은 매년 결제한 비용은 환불이 되지 않습니다. 서비스 자동 갱신으로 비용이 청구되고 있다면 구독신청을 취소해야 다음 결제일로부터 비용이 청구되지 않습니다.",
    "content_4_4_7": "각 플랜의 혜택 및 가격",
    "content_4_4_8": "각 플랜의 혜택 및 할인률에 따른 가격은 내부 사정에 의해 변경될 수 있습니다.",
    "plan_project": "항목",
    "plan_free": "Free",
    "plan_basic": "Basic",
    "plan_pro": "Pro",
    "plan_original": "정가",
    "plan_price": "가격",
    "plan_discounts": "할인률",
    "plan_discounts_prices": "할인된 가격",
    "plan_special_offer": "프로모션",
    "plan_month": "월 구독",
    "plan_year": "년 구독",
    "plan_special_des": "가입 시 30일 무료 사용 (계정당 1회, 1개의 plan만 가능)",
    "plan_content_filter": "콘텐츠 사전검수",
    "plan_open_platform": "오픈가능 채널 수",
    "plan_upload_count": "업로드 횟수",
    "plan_upload_limit_free_1": "주 1회",
    "plan_upload_limit_free_2": "Global PASS인증 시 ",
    "plan_upload_limit_free_3": "주2회",
    "plan_upload_limit_basic": "플랫폼별 일 1회",
    "plan_upload_limit_pro": "무제한",
    "plan_upload_limit_pro_a": "(플랫폼별 일 최대 5회)",
    "plan_upload_store": "1회 업로드 용량 제한",
    "plan_copyright": "저작권 보호(불펌 신고)",
    "plan_charge": "정산 수수료",
    "plan_storage": "제공 스토리지",
    "plan_profitability": "수익화 인증",
    "plan_account": "채널 소유권 인증",
    "plan_chinese_title": "중문 제목 최적화",
    "plan_cover": "썸네일 최적화",
    "plan_tag": "해시태그 최적화",
    "plan_appointment": "예약 업로드",
    "plan_upload_simul": "각 플랫폼 동시 업로드",
    "plan_plus_v": "채널 v 인증 제공",
    "content_4_5": "point 사용 규칙",
    "content_4_5_1": "adobaro 서비스의 이용 중 특정 조건에 부합하는 경우 point가 지급됩니다. 지급되는 point는 현금으로 환전할 수 없으며, 사이트에서 제공되는 일부 서비스에서 사용이 가능합니다.",
    "content_4_5_2": "지급되는 point는 adobaro의 운영 정책에 따라 변경될 수 있으며, 사용처 역시 조정될 수 있습니다.",
    "content_4_6": "유료 Plan의 해지와 환불 규정",
    "content_4_6_1": "사용자는 언제든지 유료 plan을 해지할 수 있습니다. 유료 plan을 해지하면 plan의 자동 갱신이 사용 중지됩니다.",
    "content_4_6_2": "유료 plan을 해지하면 요금이 다시 청구되지 않으며 결제 주기 마지막 날까지 유료 plan의 혜택이 유지됩니다.",
    "content_4_6_3": "유료 plan을 해지하는 시점과 유료 plan이 종료되는 시점 사이의 기간에 대해서는 환불되지 않습니다.",
    "content_4_6_4": "결제 후 14일이 지난 후 취소하는 경우 환불되지 않으며 서비스는 계약 기간이 끝날때까지 계속됩니다.",
    "content_4_6_5": "결제 후 14일이 지난 후부터 환불은 제공되지 않지만 해지 시 남은 유료 plan의 이용기간이 종료된 후, 더 이상 요금이 청구되거나 갱신되지 않습니다.",
    "content_5": "제3자 링크",
    "content_5_1": "adobaro 서비스는 기타 국제 인터넷 웹사이트 또는 리소스와 링크를 제공할 수 있습니다. 별도의 성명이 없는 이상, adobaro 는 제3자 웹사이트의 서비스를 통제할 수 없으므로 사용자의 상기 웹사이트 또는 리소스를 이용함으로 인해 발생하는 손실이나 손해에 대하여 adobaro 는 어떠한 책임도 부담하지 않습니다. 따라서 adobaro 를 떠나기 전, 기타 웹사이트 또는 리소스 방문 전 해당 서비스 조항 및 개인정보",
    "content_6": "지적재산권",
    "content_6_1": "국제 라이선스 공약, 중화인민공화국 저작권법, 특허법 및 기타 지적재산권 관련 법규에 의거, adobaro 서비스 및 본 서비스에 사용하는 소프트웨어, 기술, 상표, 소재 등 모든 지적재산권은 adobaro 가 소유합니다. '지적재산권'은 특허법, 저작권법, 상표법, 불공정경쟁 방지법 등 법률에 규정된 소유권리, 소유권 및 이익, 그리고 그 중 모든 적용, 갱신, 확장, 복구를 포함합니다.",
    "content_6_2": "사용자는 adobaro 서비스에서 사용하는 소프트웨어, 기술, 소재 등을 수정, 편집, 번역하거나 이에 관한 파생 작품을 창작할 수 없으며, 역공학, 역편집, 리버스 어셈블리 또는 기타 유사 행위를 통해 소스 코드를 취득할 수 없습니다. 이로 인해 발생하는 모든 법적 책임은 사용자가 부담하며 adobaro 는 적법하게 위약에 대한 법적 책임을 물을 수 있습니다.",
    "content_6_3": "사용자는 adobaro 서비스에서 사용하는 소프트웨어, 기술, 소재 등을 악의적으로 수정, 복제, 전파할 수 없습니다. 이로 인해 타인에게 손해가 발생하거나 adobaro 회사 이미지에 손상이 발생한 경우 귀하는 이에 합당한 법적 책임을 부담해야 합니다.",
    "content_6_4": "사용자는 adobaro 의 라이선스 성명, 상표 또는 기타 권리 성명을 임의로 삭제, 마스킹, 수정할 수 없습니다. adobaro 에서 디자인한 패턴 및 기타 패턴, 제품 및 서비스 명칭은 전부 adobaro 가 소유하는 상표, 로고에 속합니다. 사용, 복제 또는 기타 용도로의 사용을 금합니다.",
    "content_6_5": "adobaro 는 자체 제작 콘텐츠 및 기타 라이선스를 취득하여 독점하는 콘텐츠에 대하여 완전한 지적재산권을 소유하며, adobaro 의 허가 없이는 그 어떤 기관이나 개인도 사적으로 게시, 전파 또는 스트리밍을 제공하여 adobaro 의 지적재산권을 침해할 수 없습니다. 이를 위반한 경우 adobaro 는 권익 침해 행위자에게 법적 책임을 추궁할 수 있습니다.",
    "content_6_6": "adobaro 가 소유하는 지적재산권은 사용자의 사용 행위로 인한 특허 이전이 발생하지 않습니다.",
    "content_7": "면책 조항",
    "content_7_1": "adobaro 는 모든 포함, 경유, 링크, 다운로드 또는 관련 웹서비스를 통해 취득한 콘텐츠, 정보 또는 광고에 대하여 정확성과 신뢰성을 보장하지 않습니다; 사용자가 본 서비스를 통해 진행하는 홍보와 게시를 통해 성립된 매매 또는 제품, 정도 또는 자료의 취득에 대하여 adobaro 는 보증의 책임을 지지 아니합니다. 사용자는 본 서비스의 위험을 스스로 부담합니다.",
    "content_7_2": "별도의 명확한 서명 규정이 없는 한, adobaro 에서 귀하에게 제공하는 모든 제품과 서비스는 '현재 상태'와 '현존'을 전제로 제공됩니다.",
    "content_7_3": "adobaro 는 다음 사항을 보장하지 않습니다(다음을 포함하되 이에 국한하지 않음):",
    "content_7_3_1": "adobaro 에서 제공하는 웹사이트, 고객 단말 등 소프트웨어가 비록 adobaro 테스트를 거쳤으나, 기술 자체의 한계로 인해 adobaro 는 기타 소프트웨어, 하드웨어, 시스템과의 완전한 호환성을 보장할 수 없습니다. 호환 문제가 발생할 경우 사용자는 이를 adobaro 에 신고하고 기술지원을 받을 수 있습니다. 문제가 해결되지 아니할 경우, 사용자는 adobaro 서비스를 언로드하거나 사용을 중단할 수 있습니다.",
    "content_7_3_2": "adobaro 서비스에는 Internet 서비스가 관련되므로 다양한 부분에서 불안정한 요소의 영향을 받을 수 있습니다. 불가항력적 사건, 해커 공격, 시스템 불안정, 네트워크 중단, 사용자 전원 차단, 통신 선로 등 요인으로 인해 adobaro 서비스가 중단되거나 고객의 수요를 만족하지 못하는 상황이 발생할 수 있습니다. adobaro 는 adobaro 서비스가 고객의 사용 요건을 충족할 수 있음을 보장하지 않습니다.",
    "content_7_3_3": "adobaro 에서 제공하는 고객 단말 소프트웨어는 네트워크 경로로 다운로드, 전송할 수 있으므로, adobaro 지정 공식 다운로드가 아니거나, adobaro 가 가정한 경로가 아닌 곳에서 adobaro 관련 소프트웨어를 다운로드하는 경우 adobaro 는 바이러스 감염 여부, 트로이 감염 여부 등 해킹 소프트웨어로부터의 안전성을 보장할 수 없으며, 고객이 이로 인해 입게 되는 모든 직접 또는 간접 손실에 대해 배상 등 법적 책임을 부담하지 아니합니다.",
    "content_7_3_4": "adobaro 는 adobaro 서비스, 제품의 안전성, 신뢰성, 즉시성 및 성능에 관한 보장을 제공하지 않습니다.",
    "content_7_3_5": "adobaro 에서 제공하는 모든 제품, 서비스 또는 기타 소재가 사용자의 기대에 부합함을 보장하지 않습니다.",
    "content_7_4": "사용자가 adobaro 서비스를 거치지 않고 다운로드 또는 취득한 모든 자료로 인한 위험은 사용자가 스스로 부담하며, 이로 인해 사용자의 컴퓨터 시스템이 파괴되거나 자료가 유실된 경우 모든 책임은 사용자가 부담합니다.",
    "content_7_5": "다음 사유로 발생한 이윤, 영업이익, 자료의 손실 또는 기타 유형 또는 무형의 손실에 대하여 adobaro 는 그 어떠한 직접, 간접, 부수, 파생 또는 징벌적 배상도 제공하지 않습니다:",
    "content_7_5_1": "adobaro 서비스 전체 또는 일부를 사용할 수 없음;",
    "content_7_5_2": "adobaro 서비스를 통해 구매하거나 취득한 제품, 자료 또는 서비스;",
    "content_7_5_3": "사용자 자료가 허가 없이 사용 또는 수정;",
    "content_7_5_4": "기타 adobaro 서비스 관련 사항.",
    "content_7_6": "사용자는 반드시 자신의 계정과 비밀번호를 적절히 관리하고 비밀번호 보안성을 강화하여 계정 유출이나 도용을 막아야 합니다. 사용자 계정 유출 또는 도용으로 인해 발생한 손실에 대하여 adobaro 는 보상의 책임을 지지 않습니다. 전신 및 네트워크 통신 부분의 통신 선로 고장, 네트워크 또는 컴퓨터 고장, 시스템 불안정, 불가항력(서버 다운 등) 등 adobaro 가 아닌 사유로 사용자 계정, 계정 내 자산 유실, 감소가 발생한 경우, adobaro 는 보상의 책임을 지지 않습니다.",
    "content_7_7": "사용자는 자발적으로 무료 다운로드 및 adobaro 서비스 이용을 선택하였으므로 위험은 스스로 부담합니다. 이는 사용자가 adobaro 서비스를 이용하는 과정에서의 모든 행위 및 adobaro 서비스 이용으로 인해 발생하는 모든 불이익을 포함하되 이에 국한하지 않습니다. adobaro 서비스 다운로드 및 사용으로 인해 컴퓨터 시스템에 손상이나 데이터 유실이 발생한 경우, 모든 책임은 사용자가 스스로 부담합니다.",
    "content_7_8": "사용자의 인증 정보가 허위임으로 인해 계정, 계정 내 자산 유실, 감소가 발생하여 복구가 불가한 경우 adobaro 는 그 어떠한 법적 책임도 부담하지 않습니다.",
    "content_7_9": "adobaro 관련 플랫폼 서비스가 시스템 유지보수 또는 업그레이드 등의 필요로 잠시 중단되는 경우 사전에 이를 공지합니다. 하드웨어 고장 또는 기타 불가항력적 사유로 서비스 중단이 발생한 경우, 서비스 중단 기간 발생한 모든 불편과 손실에 대하여 adobaro 는 그 어떠한 책임도 부담하지 않습니다. adobaro 의 조정으로 인해 정보 유실 및/또는 기타 결과가 초래된 경우, adobaro 는 그 어떠한 책임도 부담하지 않습니다. 중국 플랫폼이 서비스를 중단하는 경우, adobaro 는 해당 플랫폼에서 사전에 통지한 내용을 즉시 귀하에게 통보합니다. 중국 플랫폼 시스템 중단으로 인해 초래된 불편 또는 손실에 대하여 adobaro 는 그 어떠한 책임도 부담하지 않습니다.",
    "content_8": "서비스 변경, 중단, 종료",
    "content_8_1": "귀하는 adobaro 가 경영 전략에 따른 조정으로 인해 서비스 콘텐츠에 변경이 발생하거나 서비스 중단, 종료가 발생할 수도 있음을 인지하고 이에 동의합니다.",
    "content_8_2": "adobaro 에 합병, 분리, 인수, 자산 양도가 발생할 경우, adobaro 는 제3자에게 본 서비스 관련 자산을 양도할 수 있음을 인지하고 이에 동의합니다; adobaro 는 또한 일방적으로 귀하에게 통보 후 본 계약의 일부 또는 전부 서비스 및 이에 상응하는 권리와 의무를 제3자에게 양도하여 운영 또는 이행할 수 있음을 인지하고 이에 동의합니다.",
    "content_8_3": "adobaro 서비스 이용 시 위법하거나 본 계약 및 기타 adobaro 규정, 사회 공공질서와 미풍양속에 위배되고/또는 타인의 합법적 권익을 침해하는 경우, adobaro 는 별도의 통보없이 일방적으로 귀하에게 제공하는 일부 또는 전체 서비스를 중단하거나 종료할 수 있음을 인지하고 이에 동의합니다.",
    "content_8_4": "귀하가 법률, 공공질서, 미풍양속을 위반하고/또는 타인의 합법적 권익을 침해하는 행위를 한 경우 이로 인해 adobaro 및/또는 타인의 명예, 명성 또는 기타 합법적 권익에 악영향을 미쳤다면 adobaro 는 별도의 통보없이 일방적으로 귀하에게 제공하는 일부 또는 전체 서비스를 중단하거나 종료할 수 있음을 인지하고 이에 동의합니다.",
    "content_8_5": "adobaro 는 귀하에게 서비스를 종료한 뒤, 적용 법률 요건에 따라 귀하의 개인정보를 삭제하거나 익명화할 수 있으며, 적법한 기간과 방식으로 귀하가 플랫폼에 남긴 기타 콘텐츠와 정보를 보관할 수 있습니다.",
    "content_9": "탈퇴",
    "content_9_1": "사용자는 adobaro 에 계정 탈퇴 신청을 제출할 수 있고, adobaro 고객센터에서 계정(법적으로 본 계약 또는 기타 adobaro 규정에 별도 약정된 경우 제외)을 탈퇴할 수 있습니다.",
    "content_9_2": "유의사항: adobaro 계정 탈퇴 후에는 동일한 계정으로 adobaro 의 모든 제품과 서비스 및 제품과 서비스 중 제3자와 협력하는 서비스 콘텐츠를 등록, 이용할 수 없으며, adobaro 역시 해당 계정으로 귀하에게 제공하는 각종 제품과 서비스를 중단합니다. 이는 사용자의 사후관리 및 권익 보호에 불편을 야기할 수 있습니다. adobaro 계정 탈퇴 후에는 복구가 불가능합니다. 따라서 탈퇴 전 신중하게 고려해야 합니다.",
    "content_9_3": "adobaro 계정을 탈퇴할 경우, 귀하는 계정 탈퇴 후에도 해당 UID 가 여전히 존재하지만 해당 계정과 관련된 다음의 권익을 누리지 못하게 됨을 인지하고 이에 동의합니다:",
    "content_9_3_1": "계정 탈퇴 후에는 해당 계정으로 재등록하거나 adobaro 의 모든 제품과 서비스를 이용할 수 없습니다;",
    "content_9_3_2": "계정 탈퇴 후에는 해당 계정으로 등록, 사용하던 adobaro 의 모든 제품과 서비스 내 코든 콘텐츠, 정보, 데이터, 히스토리가 전부 삭제 또는 익명화 되며, 검색, 방문, 취득, 사용, 복구가 불가합니다. 이는 다음을 포함하되 이에 국한하지 않습니다: 플랫폼을 개통했던 계정 정보(사진, 별명, 서명 등), 바인딩 정보;",
    "content_9_3_3": "계정 탈퇴 후, 해당 계정의 거래 기록은 삭제되어 복구 불가능합니다.",
    "content_9_3_4": "계정 탈퇴 후, adobaro 는 더 이상 사용자에게 그 어떠한 계정 관련 서비스도 제공하지 아니합니다;",
    "content_9_3_5": "귀하는 계정 탈퇴를 통해 해당 계정의 adobaro 제품 및 서비스 사용 기간에 생성하였으나 소비를 완료하지 못한 권익 및 장래 예상 이익을 포기하는 데 동의합니다. adobaro 는 해당 계정의 전체 권익을 삭제할 수 있으며 이는 다음을 포함하되 이에 국한하지 않습니다: 만료되지 않은 회원권, 미사용 쿠폰, 게임 캐릭터의 사이버 화폐 및 툴, adobaro 각 제품 및/또는 서비스 내 각종 신분상 권익, adobaro 각 제품 및/또는 서비스에서 이미 구매하여 기한이 만료되지 아니한 온라인 서비스 콘텐츠, 기타 이미 생성하였으나 소비를 완료하지 못한 권익 또는 장래 예상 이익.",
    "content_9_4": "adobaro 계정 탈퇴 신청 전, 귀하의 계정 보안 및 재산권익을 보호하기 위해 반드시 탈퇴를 신청할 계정이 다음 조건을 충족하는지 확인해야 합니다. 이는 다음을 포함하되 이에 국한하지 않습니다:",
    "content_9_4_1": "계정 사용자가 공식 채널을 통해 등록하고 본 계약 및 관련 규정에 부합하는 계정일 것; 또한 사용자 본인의 회원 계정일 것;",
    "content_9_4_2": "고객센터 탈퇴 절차에 따라 탈퇴를 진행할 것;",
    "content_9_4_3": "계정이 보안 상태가 아닐 것: 비밀번호 찾기 신청상태, 휴대전화번호 수정 중이 아닐 것; 처리 중인 불만, 신고가 없을 것; 기타 안전/이상 상태가 아닐 것;",
    "content_9_4_4": "계정 내 처리 중인 거래가 없을 것;",
    "content_9_4_5": "계정 내에 사용자가 신청하였으나 인출이 완료되지 않은 거래가 없을 것;",
    "content_9_4_6": "계정과 adobaro 전체 업무에 계약 관계 또는 아직 계약 기간인(메인 사회자 계약 등) 사안이 없을 것;",
    "content_9_4_7": "계정이 기타 서비스를 개통했던 경우 해당 서비스를 스스로 취소할 것;",
    "content_9_4_8": "기타 필요한 조건.",
    "content_9_5": "귀하가 9.2 와 9.3 조항을 충분히 숙지 및 이해하고 9.4 조항의 모든 조건을 충족한 경우 고객센터에 계정 탈퇴 신청을 할 수 있습니다. 고객센터는 귀하의 계정 보안상태 및 귀하의 관련 제품과 서비스 사용 상황을 심사한 뒤 귀하의 계정이 조건에 부합하는지 종합적으로 판단합니다.",
    "content_9_6": "adobaro 계정을 탈퇴하면 귀하와 체결했던 관련 사용자 계약, 기타 권리 및 의무 등이 종료(지속적으로 효력이 유지되도록 약정하였거나 법적으로 별도 규정된 부분 제외)됩니다. 동시에, 귀하는 다음을 인지하고 이에 동의합니다: 귀하의 계정이 탈퇴되었더라도 계약기간 내 관련 법률, 관련 계약, 규칙 등에 따라 부담해야 할(수도 있는) 책임은 면제되거나 감면되지 아니합니다. 또한, 귀하 계정 탈퇴 후, 귀하가 adobaro 에서 사용하는 휴대전화번호로 개통했던 모든 중국 플랫폼 계정이 전부 탈퇴되므로 adobaro 를 통해 중국 플랫폼으로 귀하의 콘텐츠를 배포할 수 없으며, 탈퇴를 완료한 시점부터 adobaro 내에서의 모든 귀하 수익도 인출할 수 없습니다. 이로 인해 발생하는 adobaro 및 플랫폼 계정의 수익 손실은 귀하가 부담하며 adobaro 는 귀하가 계정을 탈퇴함으로써 그 어떠한 배상이나 반환의 의무도 부담하지 아니합니다.",
    "content_10": "개인정보 정책",
    "content_10_1": "adobaro 는 사용자의 개인정보 및 사생활을 중시합니다. 개인정보란, 전자 또는 기타 방식으로 단독 또는 기타 정보와 함께 특정 자연인의 신분 또는 특정 자연인의 활동 상황을 식별할 수 있는 각종 정보를 말합니다. 귀하가 adobaro 제품 및/또는 서비스를 다운로드, 설치, 구동, 검색, 등록, 가입, 사용할 때, adobaro 는 플랫폼에 공지된 <adobaro 개인정보 정책>의 약정에 따라 귀하의 개인정보를 처리하고 보호합니다. 따라서 귀하는 <adobaro 개인정보 정책>을 꼼꼼히 읽고 필요 시 <adobaro 개인정보 정책>에 따라 필요한 적절한 선택을 해야 합니다.",
    "content_10_2": "귀하는 <adobaro 개인정보 정책>을 숙지하고 충분히 이해한 뒤 adobaro 제품 및/또는 서비스를 사용해야 합니다. 정책 내용에 동의하지 아니할 경우 adobaro 제품 및/또는 서비스의 정상 사용에 제약을 받거나 adobaro 가 추구하는 서비스 효과를 누리지 못할 수 있습니다. adobaro 가 제공하는 제품 및/또는 서비스를 지속적으로 사용하는 행위는 귀하가 <adobaro 개인정보 정책>(업데이트 버전 포함)을 충분히 이해하고 동의하였음을 의미하는 것으로 간주합니다.",
    "content_10_3": "귀하가 <adobaro 개인정보 정책> 또는 귀하의 개인정보 관련 내용에 대하여 문의(질문, 신고 등 포함)가 있을 경우, <adobaro 개인정보 정책>에 공지된 연락처로 연락할 수 있습니다.",
    "content_11": "미성년자 조항",
    "content_11_1": "adobaro 는 미성년자 보호에 힘씁니다. 귀하가 미성년자인 경우 반드시 법적 보호자의 관리 감독 하에 본 계약을 읽고, 법정 보호자의 동의를 얻은 뒤 adobaro 제품 및/또는 서비스를 사용할 수 있습니다.",
    "content_11_2": "법정 보호자는 자녀의 인터넷 사용상 안전 문제에 충분히 주의하여 각종 위험을 미연에 방지해야 합니다. adobaro 는 미성년자의 adobaro 를 사용한 제품/서비스 소비를 권장하지 않으며, 만약 소비하는 경우 미성년자는 반드시 법정 보호자의 명의로 소비를 신청하거나 법정 보호자의 명시적 동의 하에 이를 진행해야 합니다. 미성년자가 adobaro 서비스를 이용하는 행위는 법정 보호자의 허가를 받은 것으로 간주합니다.",
    "content_11_3": "adobaro 는 미성년자가 adobaro 서비스를 이용할 경우 온라인 학습에 도움이 되고 인터넷과 현실의 구분을 명확히 하며 과도하게 인터넷에 심취하여 일상 학습에 영향을 미치지 않도록 주의해야 합니다.",
    "content_11_4": "미성년자의 개인정보 보호에 관하여 adobaro 는 플랫폼에 공지된 <adobaro 개인정보 정책>의 설명과 방법을 엄격하게 준수하여 이행합니다.",
    "content_12": "법률의 적용 및 관할권",
    "content_12_1": "본 계약의 효력 발생, 이행, 해석 및 분쟁의 해결은 일괄 중화인민공화국의 법률을 따릅니다. 본 조항에서 중화인민공화국 현행 법률에 저촉되어 계약의 일부 무효하게 되더라도, 기타 부분의 효력에 영향을 미치지 아니합니다. 양 당사자는 분쟁 발생 시 최신 버전의 <adobaro 사용자 사용 계약서>를 기준으로 하는데 동의합니다.",
    "content_12_2": "만일 본 계약의 내용 또는 계약의 이행에 대하여 분쟁이 발생한 경우, 우호적인 협의를 최우선으로 합니다; 협의가 이루어지지 아니할 경우 각 당사자는 해당 사안을 adobaro 중재위원회에 회부하여 규정에 따라 중재하는데 동의합니다. 중재 판결은 1심으로 종결하며 각 당사자에게 법적 구속력을 갖습니다.",
    "content_12_3": "논쟁을 피하기 위해, 반대되는 서면 약정이 존재하지 않는 한, 본 조항의 법률 적용과 관할 조건의 효력은 본 계약과 기타 adobaro 규칙에 전부 적용되며, 수정, 보충, 갱신을 전부 포함합니다.",
    "content_13": "계약의 수정 및 통보",
    "content_13_1": "adobaro 는 국가 정책, 기술 조건, 제품 성능 등의 변화에 따라 본 계약을 수정할 수 있고, 수정한 계약서를 배포해야 합니다.",
    "content_13_2": "전술한 내용이 배포되면 adobaro 는 적절한 방식(팝업, 우편, 알림, 웹사이트 공지 등을 포함하되 이에 국한하지 않음)으로 귀하에게 갱신 내용을 알려 귀하가 즉시 본 계약의 최신 버전을 인지하도록 합니다.",
    "content_13_3": "본 계약의 명칭, 챕터 제목 등은 열람 편의를 위해 만들어진 것이며 본 계약은 플랫폼에 비교적 많이 게시되므로 모든 계약의 명칭을 전부 변경하려면 일정 시간이 소요됩니다. 해당 기간 내 2개의 명칭이 동시에 존재하더라도 본문의 모든 조항의 의미와 해석에 영향을 미치지 아니합니다.",
    "content_13_4": "수정된 내용은 본 계약과 불가분의 일부가 되므로 귀하는 동등하게 준수해야 합니다. 수정된 계약서에 대해 의견이 있을 경우 즉시 adobaro 및 관련 서비스 등록 및 사용을 중지해야 하며, 지속적으로 adobaro 및 관련 서비스를 이용하거나 등록할 경우, 귀하가 이미 갱신된 계약을 충분히 인지하고 이해했으며 본 계약의 구속을 수용하였음으로 간주합니다.",
    "content_13_5": "본 사용자 계약서의 내용은 기타 언어로 번역되어 참고용으로 제공합니다. 번역본과 본 사용자 계약의 원본이 불일치할 경우 반드시 중문 버전을 기준으로 합니다."
  }
};export default ko